import { RefObject, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useBreakpointRange from 'src/hooks/useBreakpointRange';

import ConditionalWrap from '../ConditionalWrap';

import DesktopSecondaryNavigation from './DesktopSecondaryNavigation';
import MobileSecondaryNavigation from './MobileSecondaryNavigation';
import * as S from './styles';

export interface LinkList {
  children?: {
    exact?: boolean;
    hideCondition?: boolean;
    link: string;
    onClick?: () => void;
    title: string;
  }[];
  exact?: boolean;
  hideCondition?: boolean;
  link: string;
  onClick?: () => void;
  parentLink?: string;
  title: string;
}

interface Props {
  linkList: LinkList[];
  linksRef?: RefObject<HTMLDivElement>;
  navTitle: string;
}

const SecondaryNavigation = ({ linkList, linksRef, navTitle }: Props) => {
  const location = useLocation();
  const { isInDesktopBreakpointRange } = useBreakpointRange();
  const [selectedParentLink, setSelectedParentLink] = useState<Props['linkList'][0] | null>(null);

  const findParentLink = useCallback((pathname: string, links: LinkList[]) => {
    const isExactMatch = (link: string, pathname: string) => pathname === link.split('?')[0];

    const isPartialMatch = (link: string, pathname: string) =>
      pathname.includes(link.split('?')[0]);

    return links.find(link => {
      const isExact = link.exact ?? false;
      if (link.parentLink !== undefined) {
        return pathname.includes(link.parentLink);
      }
      return isExact ? isExactMatch(link.link, pathname) : isPartialMatch(link.link, pathname);
    });
  }, []);

  useEffect(() => {
    const parentLink = findParentLink(location.pathname, linkList);
    setSelectedParentLink(parentLink ?? null);
  }, [location.pathname, linkList, findParentLink]);

  return (
    <S.SecondaryNavigation.Root
      className={selectedParentLink?.children !== undefined ? 'active-has-children' : undefined}
    >
      <ConditionalWrap
        condition={isInDesktopBreakpointRange}
        wrapper={children => (
          <S.SecondaryNavigation.Wrapper>
            <S.SecondaryNavigation.Text>{navTitle}</S.SecondaryNavigation.Text>
            {children}
          </S.SecondaryNavigation.Wrapper>
        )}
      >
        {isInDesktopBreakpointRange ? (
          <DesktopSecondaryNavigation
            linkList={linkList}
            linksRef={linksRef}
            location={location}
            selectedParentLink={selectedParentLink}
          />
        ) : (
          <MobileSecondaryNavigation
            linkList={linkList}
            linksRef={linksRef}
            location={location}
            navTitle={navTitle}
            selectedParentLink={selectedParentLink}
          />
        )}
      </ConditionalWrap>
    </S.SecondaryNavigation.Root>
  );
};

export default SecondaryNavigation;
