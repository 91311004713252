import { Cell, Column, Row, TableBody, TableHeader } from 'react-stately';
import EmptyTableState from 'src/components/Table/EmptyState';
import TableCenterCell from 'src/components/Table/TableCenterCell';
import TableLinkedCell from 'src/components/Table/TableLinkedCell';
import TableNumberCell from 'src/components/Table/TableNumberCell';
import { Education } from 'src/pages/Dashboard/utils';

import CommunityCheck from '../CommunityCheck';

import * as S from './styles';

interface Props {
  educations: Education[];
}

const DesktopTable = ({ educations }: Props) => (
  <S.EducationTable
    aria-label="Completed courses table"
    renderEmptyState={() => (
      <EmptyTableState
        colSpan={7}
        message="No courses have been completed."
      />
    )}
  >
    <TableHeader>
      <Column key="name">
        <S.TableHeaderCell>NAME</S.TableHeaderCell>
      </Column>
      <Column>ORGANIZATION</Column>
      <Column>
        <S.TableHeaderCell $align="end">YEAR</S.TableHeaderCell>
      </Column>
      <Column>
        <S.TableHeaderCell $align="end">CREDITS/HOURS</S.TableHeaderCell>
      </Column>
      <Column>
        <S.TableHeaderCell $align="center">BIPOC</S.TableHeaderCell>
      </Column>
      <Column>
        <S.TableHeaderCell $align="center">LGBQ</S.TableHeaderCell>
      </Column>
      <Column>
        <S.TableHeaderCell $align="center">TGNC</S.TableHeaderCell>
      </Column>
    </TableHeader>
    <TableBody>
      {educations.map(education => (
        <Row
          key={education.id}
          data-cy="completed-course"
        >
          <Cell data-cy="course-name">
            <TableLinkedCell to={`/dashboard/education/all-education/${education.courseId}`}>
              {education.courseName}
            </TableLinkedCell>
          </Cell>
          <Cell data-cy="course-organization">{education.organization}</Cell>
          <Cell data-cy="course-year">
            <TableNumberCell>{education.year}</TableNumberCell>
          </Cell>
          <Cell data-cy="course-credits">
            <TableNumberCell>{education.credits}</TableNumberCell>
          </Cell>
          <Cell data-cy="course-bipoc">
            <TableCenterCell>
              <CommunityCheck
                communities={education.communities}
                community="bipoc"
              />
            </TableCenterCell>
          </Cell>
          <Cell data-cy="course-lgbq">
            <TableCenterCell>
              <CommunityCheck
                communities={education.communities}
                community="lgbq"
              />
            </TableCenterCell>
          </Cell>
          <Cell data-cy="course-tgnc">
            <TableCenterCell>
              <CommunityCheck
                communities={education.communities}
                community="tgnc"
              />
            </TableCenterCell>
          </Cell>
        </Row>
      ))}
    </TableBody>
  </S.EducationTable>
);

export default DesktopTable;
