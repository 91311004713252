import { cloneElement, ReactElement, useRef } from 'react';
import { AriaPopoverProps, useOverlayTrigger } from 'react-aria';
import { OverlayTriggerProps, useOverlayTriggerState } from 'react-stately';

import Dialog from './Dialog';
import * as S from './styles';

interface Props extends OverlayTriggerProps {
  children: ReactElement;
  className?: string;
  content: ReactElement;
  placement?: AriaPopoverProps['placement'];
  showArrow?: boolean;
  showBorder?: boolean;
  theme?: 'dark' | 'light';
  title?: string;
  width?: number;
}

const PopoverTrigger = ({
  children,
  className,
  content,
  placement = 'top',
  showArrow = true,
  showBorder = false,
  theme = 'light',
  title,
  width,
  ...overlayTriggerProps
}: Props) => {
  const ref = useRef<HTMLButtonElement>(null);

  const state = useOverlayTriggerState(overlayTriggerProps);

  const { overlayProps, triggerProps } = useOverlayTrigger({ type: 'dialog' }, state, ref);

  return (
    <>
      {cloneElement(children, { ref, ...triggerProps })}
      {state.isOpen && (
        <S.Popover
          $hasBorder={showBorder}
          $placement={placement}
          $theme={theme}
          $width={width}
          offset={10}
          placement={placement}
          showArrow={showArrow}
          state={state}
          triggerRef={ref}
        >
          <Dialog
            className={className}
            {...overlayProps}
            theme={theme}
            title={title}
          >
            {content}
          </Dialog>
        </S.Popover>
      )}
    </>
  );
};

export default PopoverTrigger;
