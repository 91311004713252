import capitalize from 'lodash/capitalize';
import { Key, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Item } from 'react-stately';
import ButtonWithMenu from 'src/components/Buttons/ButtonWithMenu';
import PageTitle from 'src/components/PageTitle';
import CompletedCollectionsTable from 'src/pages/components/CompletedCollectionsTable';
import CompletedCoursesTable from 'src/pages/components/CompletedCoursesTable';

import * as S from './styles';

const CompletionsAndCertificates = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [view, setView] = useState<'collections' | 'courses'>(
    searchParams.get('view') !== null
      ? (searchParams.get('view') as 'collections' | 'courses')
      : 'collections'
  );

  const handleViewChange = (view: Key) => {
    setSearchParams(params => {
      params.set('view', view.toString());
      return params;
    });
  };

  useEffect(() => {
    setView(
      searchParams.get('view') !== null
        ? (searchParams.get('view') as 'collections' | 'courses')
        : 'collections'
    );
  }, [searchParams]);

  return (
    <S.FullWidthMain>
      <PageTitle
        description="A collection is a series of e-learning modules designed to grow your cultural competence in a specific focus area. Each course within a collection builds on the last as you advance through facts and figures, case vignettes, video, quizzes, and more."
        title="Completions and Certificates"
        titleVariant="h1"
      />
      <S.TableActionsWrapper>
        <ButtonWithMenu
          customWidth={220}
          data-cy="completions-view-button"
          label={`View: ${capitalize(view)}`}
          onAction={handleViewChange}
          placement="bottom end"
          variant="plain"
        >
          <Item key="collections">
            <S.OptionWrapper>
              Collections{' '}
              {view === 'collections' ? (
                <S.CheckedIcon
                  aria-label="checked"
                  data-cy="is-selected-icon"
                  role="img"
                />
              ) : null}
            </S.OptionWrapper>
          </Item>
          <Item key="courses">
            <S.OptionWrapper>
              Courses{' '}
              {view === 'courses' ? (
                <S.CheckedIcon
                  aria-label="checked"
                  data-cy="is-selected-icon"
                  role="img"
                />
              ) : null}
            </S.OptionWrapper>
          </Item>
        </ButtonWithMenu>
      </S.TableActionsWrapper>
      {view === 'collections' ? <CompletedCollectionsTable /> : <CompletedCoursesTable />}
    </S.FullWidthMain>
  );
};

export default CompletionsAndCertificates;
