import { ReactComponent as ExportIcon } from '@material-design-icons/svg/round/download.svg';
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg';
// TODO: import `Sortable` from `'react-aria'` once it stops throwing a TS error.
import { Sortable } from '@react-types/shared';
import * as Sentry from '@sentry/react';
import { Key, useEffect, useRef, useState } from 'react';
import {
  AriaCheckboxGroupProps,
  AriaComboBoxOptions,
  AriaSearchFieldProps,
  AriaSelectProps,
  AriaSwitchProps,
  useNumberFormatter
} from 'react-aria';
import { useSearchParams } from 'react-router-dom';
import {
  Cell,
  Column,
  Item,
  Row,
  SortDescriptor,
  SortDirection,
  TableBody,
  TableHeader,
  useAsyncList
} from 'react-stately';
import Button from 'src/components/Buttons/Button';
import FilterDropdown from 'src/components/FilterDropdown';
import CheckboxGroup from 'src/components/FormFields/CheckboxGroup';
import GroupCheckbox from 'src/components/FormFields/CheckboxGroup/GroupCheckbox';
import ComboBox from 'src/components/FormFields/ComboBox';
import SearchField from 'src/components/FormFields/SearchField';
import Select from 'src/components/FormFields/Select';
import Switch from 'src/components/FormFields/Switch';
import PageTitle from 'src/components/PageTitle';
import Pagination from 'src/components/Pagination';
import DataContainer from 'src/components/Reporting/DataContainer';
import DataHighlight from 'src/components/Reporting/DataHighlight';
import DataReportRow from 'src/components/Reporting/DataReportRow';
import Table from 'src/components/Table';
import CommunityFilter from 'src/components/Table/Filters/CommunityFilter';
import TableNumberCell from 'src/components/Table/TableNumberCell';
import { COMMUNITIES } from 'src/pages/constants';
import { filtersObjectToParams, sortFilterParams } from 'src/utils/filterUtils';
import isNonEmptyString from 'src/utils/isNonEmptyString';
import { capitalize } from 'src/utils/stringTransformations';

import Spinner from '../../../../components/Spinner';
import useOpenErrorModalDialog from '../../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../../../hooks/useUser';
import { downloadCsv } from '../../../../utils/downloadCsv';
import TableEmptyState from '../../components/TableEmptyState';
import useGetOrganizationEducation from '../../hooks/useGetOrganizationEducation';
import useGetOrganizationEducationOverview, {
  OrganizationEducationOverviewFilters
} from '../../hooks/useGetOrganizationEducationOverview';
import { USER_TYPES } from '../../hooks/useGetOrganizationUsers';
import {
  Collection,
  EducationalResource,
  generateCollection,
  generateEducationalResource
} from '../../utils';
import { organizationFiltersToSearch, organizationSearchToFilters } from '../organizationFilters';

import * as S from './styles';

const VALID_FILTER_KEYS = ['communities[]', 'date_range', 'clinical', 'resource_name', 'user_name'];

const EducationEngagement = () => {
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const formatter = useNumberFormatter();
  const [searchParams, setSearchParams] = useSearchParams();

  const { bearerToken, user } = useUser();
  const fetchOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${bearerToken}`
    }
  };
  const organization = user.organization_memberships.find(
    membership => membership.member_role === 'superuser'
  )?.organization;
  const organizationName = organization?.name;
  const organizationId = organization?.id;

  const [isExporting, setIsExporting] = useState(false);

  /* -------------------- FILTERS AND URL PARAM STATES -------------------- */
  const draftState = useRef<{
    clinical: string;
    communities: string[];
    date_range: string;
    resource_name: string;
    user_name: string;
  }>({
    clinical:
      searchParams.get('clinical') === 'true'
        ? 'clinical'
        : searchParams.get('clinical') === 'false'
          ? 'non-clinical'
          : 'all',
    communities: searchParams.get('communities[]')?.toLowerCase()?.split(',') ?? [],
    date_range: searchParams.get('date_range') ?? 'all_time',
    resource_name: searchParams.get('resource_name') ?? '',
    user_name: searchParams.get('user_name') ?? ''
  });
  const [appliedFilters, setAppliedFilters] = useState<Set<Key>>(
    new Set(
      Array.from(searchParams.entries())
        .filter(
          ([key]) =>
            !key.includes('order_by') && !key.includes('page') && VALID_FILTER_KEYS.includes(key)
        )
        .flatMap(([key, value]) =>
          value.split(',').map(v => organizationSearchToFilters(key, v) as Key)
        )
    )
  );

  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [isCourseView, setIsCourseView] = useState<boolean>(
    searchParams.get('resource_type') !== null
      ? searchParams.get('resource_type') === 'course'
      : true
  );
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: searchParams.get('order_by[column]') ?? 'user_name',
    direction:
      searchParams.get('order_by[dir]') === null
        ? 'ascending'
        : ({ asc: 'ascending', desc: 'descending' }[
            searchParams.get('order_by[dir]')!
          ] as SortDirection)
  });

  const sortedParams = (key: string, value: string) => (searchParams: URLSearchParams) => {
    if (page > 1) {
      searchParams.delete('page');
    }
    if (!value) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }
    searchParams.sort();
    return searchParams;
  };

  /* -------------------- HANDLERS -------------------- */
  const handleExportCsvButtonPress = async () => {
    setIsExporting(true);

    const exportSearchParams = new URLSearchParams(searchParams);
    exportSearchParams.delete('page');
    const searchParamsString = exportSearchParams
      .toString()
      .replaceAll('BIPOC', 'bipoc')
      .replaceAll('LGBQ', 'lgbq')
      .replaceAll('TGNC', 'tgnc');

    const educationExportUrl: RequestInfo = `${
      process.env.REACT_APP_API_V2_BASE_PATH
    }/organizations/${organizationId}/educations/export${
      exportSearchParams.size === 0 ? '' : `?${searchParamsString}`
    }`;

    try {
      const response = await fetch(educationExportUrl, fetchOptions);

      let isSignedOut = false;

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          isSignedOut = true;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      if (isSignedOut) return;

      const date = new Date();
      const formattedDate = date
        .toLocaleString('en-US', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric'
        })
        .replace(/\//g, '-');
      const organizationNameNoSpaces = organizationName?.replace(/\s/g, '');
      await downloadCsv(
        response,
        `Violet_EducationEngagement_${organizationNameNoSpaces}_${formattedDate}.csv`
      );

      setIsExporting(false);
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
      setIsExporting(false);
    }
  };

  const handleFilterByCommunityChange: AriaCheckboxGroupProps['onChange'] = communities => {
    draftState.current.communities = [...communities].sort();
  };

  const handleResourceNameChange: AriaComboBoxOptions<object>['onSelectionChange'] = key => {
    const item = courseList.getItem(key as string) as Collection | EducationalResource | undefined;
    const value =
      (item as EducationalResource | undefined)?.fullTitle ??
      (item as Collection | undefined)?.title;
    draftState.current.resource_name = value ?? '';
  };

  const handleSearchByNameChange: AriaSearchFieldProps['onChange'] = value => {
    draftState.current.user_name = value;
  };

  const handleUserTypeSelectionChange: AriaComboBoxOptions<object>['onSelectionChange'] = key => {
    draftState.current.clinical = key as string;
  };

  const handleDateRangeSelectionChange: AriaSelectProps<object>['onSelectionChange'] = key => {
    draftState.current.date_range =
      (key as OrganizationEducationOverviewFilters['date_range']) ?? 'all_time';
  };

  const handleTableSortChange: Sortable['onSortChange'] = ({ column, direction }) => {
    const hasChangedColumn = column !== sortDescriptor.column;
    const newDirection = hasChangedColumn ? 'descending' : direction;
    setSortDescriptor({ column, direction: newDirection });

    setSearchParams(searchParams => {
      if (page > 1) {
        searchParams.delete('page');
      }
      searchParams.set('order_by[column]', column as string);
      searchParams.set('order_by[dir]', { ascending: 'asc', descending: 'desc' }[newDirection]);
      searchParams.sort();
      return searchParams;
    });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    setSearchParams(sortedParams('page', page > 1 ? String(page) : ''));
  };

  /* -------------------- API CALLS -------------------- */
  // COURSE LIST
  const courseList = useAsyncList({
    async load({ filterText, signal }) {
      const queryString =
        filterText !== '' ? `&${isCourseView ? 'title' : 'name'}=${filterText}` : '';
      const res = await fetch(
        `${process.env.REACT_APP_API_V2_BASE_PATH}/${
          isCourseView ? 'courses' : 'course_collections'
        }?per_page=10&organization_id=${organizationId}${queryString}`,
        { ...fetchOptions, signal }
      );
      const json = (await res.json()) as APICourseCollections | APICourses;

      const resources = isCourseView
        ? (json as APICourses).data.map(generateEducationalResource)
        : (json as APICourseCollections).data.map(fields => generateCollection(fields, user));

      return {
        items: resources
      };
    }
  });

  const handleResourceTypeToggling: AriaSwitchProps['onChange'] = value => {
    setIsCourseView(value);
    /* reset all other filters when toggling between course and collection views */
    setPage(1);
    courseList.setFilterText('');
    setSearchParams(searchParams => {
      if (page > 1) {
        searchParams.delete('page');
      }
      searchParams.delete('clinical');
      searchParams.delete('resource_name');
      searchParams.delete('user_name');
      searchParams.delete('communities[]');
      searchParams.delete('date_range');
      searchParams.set('resource_type', value ? 'course' : 'course_collection');
      searchParams.sort();
      return searchParams;
    });
    draftState.current = {
      clinical: 'all',
      communities: [],
      date_range: 'all_time',
      resource_name: '',
      user_name: ''
    };
  };

  const preserveChanges = () => {
    const communityFilters = draftState.current.communities.map(
      community => `Community of focus:${community.toString()}`
    );
    const clinicalFilterString = `User type:${draftState.current.clinical === 'all' ? 'undefined' : draftState.current.clinical === 'clinical' ? 'true' : 'false'}`;

    const updatedFilters = communityFilters
      .concat([
        clinicalFilterString,
        `User:${draftState.current.user_name}`,
        `Resource name:${draftState.current.resource_name}`,
        `Range:${draftState.current.date_range}`
      ])
      .filter(
        entry =>
          !entry.includes('null') &&
          !entry.includes('undefined') &&
          !entry.includes('all_time') &&
          isNonEmptyString(entry.split(':')[1])
      );
    setAppliedFilters(new Set(updatedFilters));
    refreshURLparams(new Set(updatedFilters));
  };

  const handleClearFilters = () => {
    setPage(1);
    courseList.setFilterText('');
    draftState.current = {
      clinical: 'all',
      communities: [],
      date_range: 'all_time',
      resource_name: '',
      user_name: ''
    };
    setAppliedFilters(new Set());
    refreshURLparams(new Set());
  };

  const handleRemoveFilter = (keys: Set<Key>) => {
    Array.from(keys).forEach(key => {
      const keyString = key.toString();
      if (keyString.includes('Community of focus:')) {
        const community = keyString.split(':')[1];
        draftState.current.communities = draftState.current.communities.filter(
          item => item !== community
        );
      } else if (keyString.includes('User:')) {
        draftState.current.user_name = '';
      } else if (keyString.includes('Range:')) {
        draftState.current.date_range = 'all_time';
      } else if (keyString.includes('Name:')) {
        draftState.current.resource_name = '';
      } else if (keyString.includes('User type')) {
        draftState.current.clinical = 'all';
      }
    });
    const updatedFilters = new Set(Array.from(appliedFilters).filter(key => !keys.has(key)));
    setAppliedFilters(updatedFilters);
    refreshURLparams(updatedFilters);
  };

  const refreshURLparams = (updatedFilters: Set<Key>) => {
    setPage(1);
    const finalParams = filtersObjectToParams(organizationFiltersToSearch, updatedFilters);
    const orderAndPageParams: [string, string][] = [
      ['page', '1'],
      ['order_by[column]', sortDescriptor.column.toString()],
      ['order_by[dir]', sortDescriptor.direction === 'descending' ? 'desc' : 'asc'],
      [
        'resource_type',
        searchParams.get('resource_type') === 'course' ? 'course' : 'course_collection'
      ]
    ];
    const sortedParams = sortFilterParams(orderAndPageParams.concat(finalParams));
    setSearchParams(sortedParams);
  };

  useEffect(() => {
    // reset the filter text when toggling between course and collection views
    courseList.setFilterText('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCourseView]);

  useEffect(() => {
    // Set initial text from URL params
    courseList.setFilterText(searchParams.get('resource_name') ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // EDUCATION
  const { education, isFetchingOrgEducation, totalOrgEducationPages, updateEducationFilters } =
    useGetOrganizationEducation(organizationId ?? '', {
      'communities[]': searchParams.get('communities[]')?.toLowerCase()?.split(',') ?? undefined,
      date_range:
        (searchParams.get('date_range') as OrganizationEducationOverviewFilters['date_range']) ??
        undefined,
      is_clinical:
        searchParams.get('clinical') !== null ? searchParams.get('clinical') === 'true' : undefined,
      order_by: {
        column: searchParams.get('order_by[column]') ?? 'user_name',
        dir: (searchParams.get('order_by[dir]') as 'asc' | 'desc' | undefined) ?? 'asc'
      },
      page: searchParams.get('page') !== null ? Number(searchParams.get('page')) : 1,
      resource_name: searchParams.get('resource_name') ?? undefined,
      resource_type:
        (searchParams.get('resource_type') as 'course_collection' | 'course' | undefined) ??
        'course',
      user_name: searchParams.get('user_name') ?? undefined
    });

  // EDUCATION OVERVIEW
  const { educationOverview, isFetchingOrgEducationOverview, updateEducationOverviewFilters } =
    useGetOrganizationEducationOverview(organizationId ?? '', {
      'communities[]': searchParams.get('communities[]')?.toLowerCase()?.split(',') ?? undefined,
      date_range:
        (searchParams.get('date_range') as OrganizationEducationOverviewFilters['date_range']) ??
        undefined,
      is_clinical:
        searchParams.get('clinical') !== null ? searchParams.get('clinical') === 'true' : undefined,
      resource_name: searchParams.get('resource_name') ?? undefined,
      resource_type:
        (searchParams.get('resource_type') as 'course_collection' | 'course' | undefined) ??
        'course',
      user_name: searchParams.get('user_name') ?? undefined
    });

  useEffect(() => {
    const sharedFilters = {
      'communities[]': searchParams.get('communities[]')?.toLowerCase()?.split(',') ?? undefined,
      date_range:
        (searchParams.get('date_range') as OrganizationEducationOverviewFilters['date_range']) ??
        undefined,
      is_clinical:
        searchParams.get('clinical') !== null ? searchParams.get('clinical') === 'true' : undefined,
      resource_name: searchParams.get('resource_name') ?? undefined,
      resource_type:
        (searchParams.get('resource_type') as 'course_collection' | 'course' | undefined) ??
        'course',
      user_name: searchParams.get('user_name') ?? undefined
    };

    updateEducationFilters({
      ...sharedFilters,
      order_by: {
        column: searchParams.get('order_by[column]') ?? 'user_name',
        dir: (searchParams.get('order_by[dir]') as 'asc' | 'desc' | undefined) ?? 'asc'
      },
      page: searchParams.get('page') !== null ? Number(searchParams.get('page')) : 1
    });

    updateEducationOverviewFilters({
      ...sharedFilters
    });
  }, [searchParams, updateEducationFilters, updateEducationOverviewFilters]);

  useEffect(() => {
    setAppliedFilters(
      new Set(
        Array.from(searchParams.entries())
          .filter(
            ([key]) =>
              !key.includes('order_by') && !key.includes('page') && VALID_FILTER_KEYS.includes(key)
          )
          .flatMap(([key, value]) =>
            value.split(',').map(v => organizationSearchToFilters(key, v) as Key)
          )
      )
    );
    draftState.current = {
      clinical:
        searchParams.get('clinical') === 'true'
          ? 'clinical'
          : searchParams.get('clinical') === 'false'
            ? 'non-clinical'
            : 'all',
      communities: searchParams.get('communities[]')?.toLowerCase()?.split(',') ?? [],
      date_range:
        (searchParams.get('date_range') as OrganizationEducationOverviewFilters['date_range']) ??
        'all_time',
      resource_name: searchParams.get('resource_name') ?? '',
      user_name: searchParams.get('user_name') ?? ''
    };
  }, [searchParams]);

  return (
    <>
      <PageTitle
        actionButton={
          <Button
            aria-label="Export csv of all education engagement history"
            data-cy="export-csv-button"
            isDisabled={isExporting}
            onPress={handleExportCsvButtonPress}
            size="regular"
            trailingIcon={ExportIcon}
          >
            {isExporting ? (
              <>
                <Spinner />
                Exporting...
              </>
            ) : (
              <>Export CSV</>
            )}
          </Button>
        }
        title="Education engagement"
        titleVariant="h1"
      />
      <S.MarginBelowContainer>
        <FilterDropdown>
          <FilterDropdown.Filters onApplyChanges={preserveChanges}>
            <ComboBox
              aria-label={`${isCourseView ? 'Course' : 'Collection'} name`}
              data-cy="search-by-resource-name-input"
              defaultInputValue={draftState.current.resource_name}
              icon={SearchIcon}
              items={courseList.items as Collection[] | EducationalResource[]}
              onInputChange={value => {
                courseList.setFilterText(value);
              }}
              onSelectionChange={handleResourceNameChange}
              placeholder={`${isCourseView ? 'Course' : 'Collection'} name`}
            >
              {item => (
                <Item
                  key={(item as EducationalResource).id}
                  textValue={(item as EducationalResource).fullTitle || (item as Collection).title}
                >
                  {(item as EducationalResource).fullTitle || (item as Collection).title}
                </Item>
              )}
            </ComboBox>
            <SearchField
              aria-label="User name"
              data-cy="search-by-user-name-input"
              defaultValue={draftState.current.user_name}
              onChange={handleSearchByNameChange}
              placeholder="User name"
            />
            <Select
              aria-label="Filter by user type"
              data-cy="user-type-filter"
              defaultSelectedKey={draftState.current.clinical}
              onSelectionChange={handleUserTypeSelectionChange}
              placeholder="Filter by user type"
            >
              {USER_TYPES.map(userType => (
                <Item key={userType}>{`${capitalize(userType)} users`}</Item>
              ))}
            </Select>
            <Select
              aria-label="Filter by date range"
              data-cy="date-range-filter"
              defaultSelectedKey={draftState.current.date_range}
              onSelectionChange={handleDateRangeSelectionChange}
              placeholder="Filter by date range"
            >
              <Item key="last_30_days">Last 30 days</Item>
              <Item key="last_90_days">Last 90 days</Item>
              <Item key="year_to_date">Year to date</Item>
              <Item key="last_12_months">Last 12 months</Item>
              <Item key="all_time">All time</Item>
            </Select>
            <CommunityFilter filterLabelText="Community of focus">
              <CheckboxGroup
                aria-labelledby="filter-label"
                defaultValue={draftState.current.communities}
                direction="horizontal"
                onChange={handleFilterByCommunityChange}
              >
                {COMMUNITIES.map(community => (
                  <GroupCheckbox
                    key={community}
                    data-cy={`community-${community}`}
                    value={community.toLowerCase()}
                  >
                    {community}
                  </GroupCheckbox>
                ))}
              </CheckboxGroup>
            </CommunityFilter>
          </FilterDropdown.Filters>
          <FilterDropdown.Tags
            onClear={handleClearFilters}
            onRemove={handleRemoveFilter}
          >
            {Array.from(appliedFilters).map(key => {
              const label = key.toString().split(':')[0];
              let value = key.toString().split(':')[1];
              if (label.toLowerCase().includes('community')) {
                value = value.toUpperCase();
              }
              return (
                <Item key={key}>
                  {label === 'User type'
                    ? value === 'true'
                      ? 'Clinical users'
                      : 'Non-clinical users'
                    : `${label}: ${
                        label === 'Range' ? capitalize(value.replaceAll('_', ' ')) : value
                      }`}
                </Item>
              );
            })}
          </FilterDropdown.Tags>
        </FilterDropdown>
        <Switch
          aria-label="View collections or courses"
          data-cy="resource-type-toggle"
          isSelected={isCourseView}
          offLabel="Collection view"
          onChange={handleResourceTypeToggling}
          onLabel="Course view"
        />
      </S.MarginBelowContainer>

      {isFetchingOrgEducationOverview || isFetchingOrgEducation ? (
        <Spinner withWrapper />
      ) : (
        <>
          <DataContainer
            data-cy="education-engagement"
            hideBackground
            hideBorder
          >
            <DataReportRow layout="quarters">
              <DataHighlight
                data-cy="organization-courses-complete"
                detailText={`${isCourseView ? 'Courses' : 'Collections'} completed`}
                numberHighlight={formatter.format(educationOverview?.educationCompletedCount ?? 0)}
                variant="small"
              />
              <DataHighlight
                data-cy="organization-hours-learning"
                detailText="Hours learning"
                numberHighlight={formatter.format(educationOverview?.educationHours ?? 0)}
                variant="small"
              />
              <DataHighlight
                data-cy="organization-avg-rating"
                detailText="Average course rating"
                isEmptyState={
                  educationOverview === undefined || educationOverview.averageCourseScore === 0
                }
                numberHighlight={
                  educationOverview !== undefined && educationOverview.averageCourseScore > 0
                    ? `${educationOverview.averageCourseScore}/5`
                    : 'N/A'
                }
                variant="small"
              />
              <DataHighlight
                data-cy="organization-credits-earned"
                detailText="CE/CME credits earned"
                numberHighlight={formatter.format(Number(educationOverview?.ceCreditsEarned ?? 0))}
                variant="small"
              />
            </DataReportRow>
          </DataContainer>

          {education.length === 0 ? (
            <TableEmptyState />
          ) : (
            <>
              <Table
                aria-label="Table listing all providers and the badges they've each obtained"
                centeredIndexes={[5, 6, 7]}
                data-cy="education-engagement-table"
                hasLinkedRows
                onSortChange={handleTableSortChange}
                sortDescriptor={sortDescriptor}
              >
                <TableHeader>
                  <Column
                    key="user_name"
                    allowsSorting
                  >
                    Name
                  </Column>
                  <Column
                    key="resource_name"
                    allowsSorting
                  >
                    {isCourseView ? 'Course' : 'Collection'}
                  </Column>
                  <Column
                    key="publisher"
                    allowsSorting={isCourseView}
                  >
                    Publisher
                  </Column>
                  <Column
                    key="completed_at"
                    allowsSorting
                  >
                    <TableNumberCell alignRight>Completion date</TableNumberCell>
                  </Column>
                  <Column key="hours">
                    <TableNumberCell alignRight>Hours</TableNumberCell>
                  </Column>
                  <Column key="BIPOC">BIPOC</Column>
                  <Column key="LGBQ">LGBQ</Column>
                  <Column key="TGNC">TGNC</Column>
                </TableHeader>
                <TableBody>
                  {education.map(resource => (
                    <Row
                      key={`${resource.user.fullName} ${resource.name}`}
                      data-cy="education-row"
                    >
                      <Cell>
                        <S.Tooltip
                          content={<S.TooltipText>{`${resource.user.fullName}`}</S.TooltipText>}
                          delay={0}
                        >
                          <S.TruncateLongText>{`${resource.user.fullName}`}</S.TruncateLongText>
                        </S.Tooltip>
                      </Cell>
                      <Cell>
                        <S.Tooltip
                          content={<S.TooltipText>{resource.name}</S.TooltipText>}
                          delay={0}
                        >
                          {resource.currentlyPublished ? (
                            <S.TruncatedLinkCell
                              data-cy="resource-link"
                              href={`/dashboard/education/${
                                isCourseView ? `all-education/` : `collections/`
                              }${resource.resourceId}`}
                              target="_blank"
                            >
                              {resource.name}
                            </S.TruncatedLinkCell>
                          ) : (
                            <S.TruncateLongResourceText>{resource.name}</S.TruncateLongResourceText>
                          )}
                        </S.Tooltip>
                      </Cell>
                      <Cell>
                        <S.Tooltip
                          content={<S.TooltipText>{resource.publisher}</S.TooltipText>}
                          delay={0}
                        >
                          <S.TruncateLongText>{resource.publisher}</S.TruncateLongText>
                        </S.Tooltip>
                      </Cell>
                      <Cell>
                        <TableNumberCell>
                          {resource.completionDate.toLocaleString('en-US')}
                        </TableNumberCell>
                      </Cell>
                      <Cell>
                        <TableNumberCell>{resource.hours}</TableNumberCell>
                      </Cell>
                      <Cell>{resource.communities.includes('bipoc') && <S.CheckCircleIcon />}</Cell>
                      <Cell>{resource.communities.includes('lgbq') && <S.CheckCircleIcon />}</Cell>
                      <Cell>{resource.communities.includes('tgnc') && <S.CheckCircleIcon />}</Cell>
                    </Row>
                  ))}
                </TableBody>
              </Table>
              <Pagination
                currentPage={page}
                setPage={handlePageChange}
                totalPages={totalOrgEducationPages}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default EducationEngagement;
