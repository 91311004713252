import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import Pagination from 'src/components/Pagination';
import Spinner from 'src/components/Spinner';
import useBreakpointRange from 'src/hooks/useBreakpointRange';
import useGetCollectionCompletions from 'src/pages/Dashboard/hooks/useGetCollectionCompletions';

import DesktopTable from './DesktopTable';
import MobileCards from './MobileCards';

const CompletedCollectionsTable = () => {
  const isNewProfileNavigationEnabled = useFeatureFlagEnabled('new_profile_navigation');
  const COMPLETIONS_PER_PAGE = isNewProfileNavigationEnabled === true ? 20 : 5;
  const { isInDesktopBreakpointRange } = useBreakpointRange();

  const [page, setPage] = useState(1);
  const {
    collectionCompletions,
    isFetching,
    totalCompletionPages,
    updateCollectionCompletionsFilters
  } = useGetCollectionCompletions({
    page,
    per_page: COMPLETIONS_PER_PAGE
  });

  useEffect(() => {
    updateCollectionCompletionsFilters({ page, per_page: COMPLETIONS_PER_PAGE });
  }, [page, updateCollectionCompletionsFilters, COMPLETIONS_PER_PAGE]);

  if (isFetching) {
    return <Spinner withWrapper />;
  }

  return (
    <>
      {isInDesktopBreakpointRange ? (
        <DesktopTable collectionCompletions={collectionCompletions} />
      ) : (
        <MobileCards collectionCompletions={collectionCompletions} />
      )}
      <Pagination
        currentPage={page}
        setPage={setPage}
        totalPages={totalCompletionPages}
      />
    </>
  );
};

export default CompletedCollectionsTable;
