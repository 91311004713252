import { transparentize } from 'polished';
import { GRAY_400_COLOR } from 'src/components/GlobalStyle';
import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const NotAvailableText = styled.div`
  display: inline-flex;
  align-items: center;
  height: 2.25rem;
  ${TEXT_STYLES.tooltipTitle}
  color: ${transparentize(0.2, GRAY_400_COLOR)};
`;
