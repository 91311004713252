import { ReactComponent as BaseCheckCircleIcon } from '@material-design-icons/svg/round/check_circle.svg';
import TableLinkedCell from 'src/components/Table/TableLinkedCell';
import TooltipTrigger from 'src/components/TooltipTrigger';
import UnstyledButton from 'src/components/UnstyledButton';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../../../constants';

export const CheckCircleIcon = styled(BaseCheckCircleIcon)`
  fill: var(--success-400);
`;

export const MarginBelowContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Tooltip = styled(TooltipTrigger)`
  display: inline-flex;
  justify-content: center;
`;

export const TooltipText = styled.span`
  ${TEXT_STYLES.tooltipText}
`;

export const TruncatedLinkCell = styled(TableLinkedCell)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 24.25rem; /* 400px - 0.75rem */
`;

export const TruncateLongResourceText = styled(UnstyledButton)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 24.25rem; /* 400px - 0.75rem */
`;

export const TruncateLongText = styled(UnstyledButton)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 11.75rem; /* 200px - 0.75rem */
`;
