export const BENCHMARK_EXPLANATION_TEXT = {
  awareness: `
    This Benchmark is awarded to providers in the top 50% of their specialty.
                        Culturally aware providers recognize inequities in health care for various identities, socio-economic statuses, and communities.
                        These providers are committed to building health equity by acknowledging that identities and culture deeply matter in health care.
  `,
  excellence: `
    This Benchmark is awarded to providers in the top 5% of their specialty.
                        Culturally excellent providers are advocates for communities.
                        They have extensive professional experience working directly with patients with specific and intersecting cultural identities.
                        Culturally excellent providers are generally pioneers and leading the industry in building health equity for the communities they specialize in.
  `,
  proficiency: `
    This Benchmark is awarded to providers in the top 25% of their specialty.
                        Culturally proficient providers are able to speak to identities and cultures in their care delivery.
                        They are actively building health equity by creating safer environments and guiding patients on their unique health journeys.
  `
};

export const LANGUAGE_SEARCH_TEXT = {
  copyClinical:
    'This helps your organization improve access to care for a wider range of patients. Please read through the proficiency definitions when selecting proficiency level.',
  copyNonClinical:
    'We collect this information to provide your organization insights into the diversity and language skills of your team, with the aim of expanding access to culturally and linguistically appropriate care.',
  titleClinical: 'Other than English, what languages can you deliver care in?',
  titleNonClinical: 'Do you speak any languages other than English?'
};
