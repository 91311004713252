import { useEffect, useState } from 'react';
import Pagination from 'src/components/Pagination';
import Spinner from 'src/components/Spinner';
import useBreakpointRange from 'src/hooks/useBreakpointRange';
import useGetEducations from 'src/pages/Dashboard/hooks/useGetEducations';

import DesktopTable from './DesktopTable';
import MobileCards from './MobileCards';

const COURSES_PER_PAGE = 20;

const CompletedCoursesTable = () => {
  const { isInDesktopBreakpointRange } = useBreakpointRange();
  const [page, setPage] = useState(1);
  const { educations, isFetching, totalEducationPages, updateEducationFilters } = useGetEducations({
    page,
    per_page: COURSES_PER_PAGE,
    user_entered: false
  });

  useEffect(() => {
    updateEducationFilters({
      page,
      per_page: COURSES_PER_PAGE,
      user_entered: false
    });
  }, [page, updateEducationFilters]);

  if (isFetching || educations === undefined) {
    return <Spinner withWrapper />;
  }

  return (
    <>
      {isInDesktopBreakpointRange ? (
        <DesktopTable educations={educations} />
      ) : (
        <MobileCards educations={educations} />
      )}
      <Pagination
        currentPage={page}
        setPage={setPage}
        totalPages={totalEducationPages}
      />
    </>
  );
};
export default CompletedCoursesTable;
