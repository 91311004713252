import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'src/utils/useDebounce';
import { useIsFirstRender } from 'usehooks-ts';

import { generateNetworkDiversity, NetworkDiversity } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

type FetchNetworkDiversity = (
  filters?: NetworkDiversityFilters,
  skipSettingIsFetching?: boolean
) => Promise<void>;

interface NetworkDiversityFilters {
  'organizations[]'?: string[];
  'specialties[]'?: string[];
}

interface UseGetNetworkDiversity {
  (filters?: NetworkDiversityFilters): {
    isFetching: boolean;
    networkDiversity: NetworkDiversity | undefined;
    updateNetworkDiversityFilters: (newFilters: NetworkDiversityFilters) => void;
  };
}

const useGetNetworkDiversity: UseGetNetworkDiversity = filters => {
  const isFirstRender = useIsFirstRender();
  const [networkDiversity, setNetworkDiversity] = useState<NetworkDiversity | undefined>();
  const [isFetching, setIsFetching] = useState(false);
  const [query, setQuery] = useState<NetworkDiversityFilters>(filters ?? {});
  const { getRequest, reportError } = useApiRequest();

  const fetchNetworkDiversity: FetchNetworkDiversity = useCallback(
    async (filters, skipSettingIsFetching = false) => {
      if (!skipSettingIsFetching) {
        setIsFetching(true);
      }

      const params = filtersToParams(filters ?? {});

      const url: RequestInfo = `${
        process.env.REACT_APP_API_BASE_PATH
      }/networks/providers/diversity${params ? `?${params}` : ''}`;

      try {
        const { data } = (await getRequest(url)) as {
          data?: APINetworksProvidersDiversity['data'];
        };
        setNetworkDiversity(data !== undefined ? generateNetworkDiversity(data) : undefined);
      } catch (error) {
        reportError(error);
      } finally {
        if (!skipSettingIsFetching) {
          setIsFetching(false);
        }
      }
    },
    [getRequest, reportError]
  );

  const debouncedFetchNetworkDiversity = useDebounce(fetchNetworkDiversity, 200);

  useEffect(() => {
    /*
      React StrictMode causes this initial fetch
      to happen twice even with no dependencies, resulting
      in a double API call w/ no filter changes.
      This is a workaround for now that will run the
      initial fetch only once after the component calling
      this hook has been mounted.
    */
    if (!isFirstRender) return;
    setIsFetching(true);
    debouncedFetchNetworkDiversity(filters);

    return () => {
      setNetworkDiversity(undefined);
      setIsFetching(false);
      setQuery({});
    };
  }, [debouncedFetchNetworkDiversity, isFirstRender, filters]);

  const updateNetworkDiversityFilters = (newFilters: Partial<NetworkDiversityFilters> = {}) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof NetworkDiversityFilters] === undefined
        ? delete newFilters[key as keyof NetworkDiversityFilters]
        : {}
    );
    if (isEqual(newFilters, query)) return;
    setQuery(newFilters);
    debouncedFetchNetworkDiversity(newFilters);
  };

  return {
    isFetching,
    networkDiversity,
    updateNetworkDiversityFilters
  };
};

export default useGetNetworkDiversity;
