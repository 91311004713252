import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'src/utils/useDebounce';

import { generatePathway, generatePathwayCompletion, Pathway } from '../utils';

import useApiRequest from './useApiRequest';

type FetchPathway = (skipSettingIsFetching?: boolean) => void;

type RefetchFetchPathway = FetchPathway;

type StartPathway = FetchPathway;

interface UseGetPathway {
  (id: string): {
    isFetching: boolean;
    pathway: Pathway | undefined;
    refetchPathway: RefetchFetchPathway;
    startPathway: StartPathway;
  };
}

const useGetPathway: UseGetPathway = id => {
  const [pathway, setPathway] = useState<Pathway | undefined>();
  const [isFetching, setIsFetching] = useState(false);
  const [pathwayId, setPathwayId] = useState<string>(id);
  const { getRequest, postRequest, reportError } = useApiRequest();

  const fetchPathway: FetchPathway = useCallback(
    async (skipSettingIsFetching = false) => {
      if (!pathwayId || pathwayId === '') return;

      if (!skipSettingIsFetching) {
        setIsFetching(true);
      }

      const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/pathways/${pathwayId}`;

      try {
        const { data } = (await getRequest(url)) as {
          data?: APIPathwaysShow['data'];
        };
        setPathway(data !== undefined ? generatePathway(data) : undefined);
      } catch (error) {
        reportError(error);
      } finally {
        if (!skipSettingIsFetching) {
          setIsFetching(false);
        }
      }
    },
    [pathwayId, getRequest, reportError]
  );

  const debouncedFetchPathway = useDebounce(fetchPathway, 200);

  useEffect(() => {
    setIsFetching(true);
    debouncedFetchPathway();
    setPathwayId(id);

    return () => {
      setPathway(undefined);
      setIsFetching(false);
    };
  }, [pathwayId, debouncedFetchPathway, id]);

  const refetchPathway: RefetchFetchPathway = useCallback(
    (skipSettingIsFetching = false) => {
      debouncedFetchPathway(skipSettingIsFetching);
    },
    [debouncedFetchPathway]
  );

  const startPathway: StartPathway = useCallback(
    async (skipSettingIsFetching = false) => {
      if (!pathwayId || pathwayId === '') return;

      if (!skipSettingIsFetching) {
        setIsFetching(true);
      }

      const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/pathways/${pathwayId}/start`;

      try {
        const { data } = (await postRequest(url, {})) as {
          data?: APIPathwaysStart['data'];
        };
        if (data !== undefined) {
          const updatedPathway = {
            ...pathway,
            completion: generatePathwayCompletion(data)
          } as Pathway;
          setPathway(updatedPathway);
        }
      } catch (error) {
        reportError(error);
      }

      if (!skipSettingIsFetching) {
        setIsFetching(false);
      }
    },
    [pathway, pathwayId, postRequest, reportError]
  );

  return { isFetching, pathway, refetchPathway, startPathway };
};

export default useGetPathway;
