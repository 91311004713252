import { AnimatePresence, motion } from 'framer-motion';
import PopoverTrigger from 'src/components/PopoverTrigger';
import useBreakpointRange from 'src/hooks/useBreakpointRange';
import { BENCHMARK_EXPLANATION_TEXT } from 'src/pages/strings';
import { getLocalStorageWithExpiration } from 'src/utils/localStorageWithExpiration';

import * as S from './styles';

interface Props {
  badges: {
    imageSrc: string;
    latestEarned: boolean;
    level: 'Awareness' | 'Excellence' | 'Proficiency';
    notYetAwarded: boolean;
  }[];
  communityAcronym: 'BIPOC' | 'LGBQ' | 'TGNC';
  earnedAtDate: string;
  score: number;
}

const BenchmarkBar = ({ badges, communityAcronym, earnedAtDate, score }: Props) => {
  const { isInMobileBreakpointRange } = useBreakpointRange();
  const latestEarnedBadges =
    getLocalStorageWithExpiration(`latestEarnedBadges`)?.toUpperCase()?.split(',') ?? [];

  return (
    <S.BenchmarkBar
      key={`${communityAcronym}-benchmark-bar`}
      className={`${communityAcronym}-benchmark-bar`}
      data-cy="benchmark-bar"
    >
      <S.CommunityTextWrapper>
        <S.CommunityAcronym>{communityAcronym}</S.CommunityAcronym>
        <S.CommunityDescription>
          {
            {
              BIPOC: 'Black, Indigenous, and People of Color',
              LGBQ: 'Lesbian, Gay, Bisexual, and Queer',
              TGNC: isInMobileBreakpointRange
                ? 'Transgender & Gender Non-Conforming'
                : 'Transgender and Gender Non-Conforming'
            }[communityAcronym]
          }
        </S.CommunityDescription>
      </S.CommunityTextWrapper>
      <S.ProgressBarContainer>
        <S.ProgressBar
          key={`${communityAcronym}-progress-bar`}
          animated
          aria-label={`${communityAcronym} Benchmark progress`}
          data-cy={`${communityAcronym}-progress-bar`}
          maxValue={100}
          minValue={0}
          value={score}
        />
        <div />
        {badges.map((badge, index) => (
          <AnimatePresence>
            <motion.div
              key={`${communityAcronym}-benchmark-section-container`}
              animate={{ opacity: 1, transform: 'scale(1)', transformOrigin: 'center' }}
              initial={{ opacity: 0, transform: 'scale(0)', transformOrigin: 'center' }}
              transition={{ delay: index * 0.5, duration: 1 }}
            >
              <PopoverTrigger
                key={`${communityAcronym}-${badge.level}`}
                content={
                  <S.Popover>
                    {badge.notYetAwarded ? (
                      <>
                        <S.Header>
                          <S.PopoverTitle>Not yet awarded</S.PopoverTitle>
                        </S.Header>
                        <S.PopoverText>
                          Based on the information you’ve input, you have not met the minimum
                          requirements to achieve the {badge.level} Benchmark. Complete additional
                          Violet collections or add relevant experience to your profile.
                        </S.PopoverText>
                      </>
                    ) : (
                      <>
                        <S.Header>
                          <S.PopoverTitle>{badge.level}</S.PopoverTitle>
                          <S.EarnedAtDate>
                            <S.CheckCircleIcon />
                            {`Earned ${earnedAtDate}`}
                          </S.EarnedAtDate>
                        </S.Header>
                        <S.PopoverText>
                          {badge.level.toUpperCase() === 'AWARENESS' &&
                            BENCHMARK_EXPLANATION_TEXT.awareness}
                          {badge.level.toUpperCase() === 'PROFICIENCY' &&
                            BENCHMARK_EXPLANATION_TEXT.proficiency}
                          {badge.level.toUpperCase() === 'EXCELLENCE' &&
                            BENCHMARK_EXPLANATION_TEXT.excellence}
                        </S.PopoverText>
                      </>
                    )}
                  </S.Popover>
                }
                placement="top left"
              >
                <S.BenchmarkWrapper>
                  <S.BenchmarkImageWrapper>
                    {badge.latestEarned &&
                      latestEarnedBadges.includes(communityAcronym.toUpperCase()) && (
                        <S.BenchmarkShineEffect data-cy="benchmark-shine-effect" />
                      )}
                    <S.Benchmark
                      alt={
                        badge.notYetAwarded
                          ? 'Benchmark not yet awarded'
                          : `${communityAcronym} ${badge.level} Benchmark`
                      }
                      height={50}
                      src={badge.imageSrc}
                    />
                  </S.BenchmarkImageWrapper>
                  <S.BenchmarkLabel>{badge.level}</S.BenchmarkLabel>
                </S.BenchmarkWrapper>
              </PopoverTrigger>
            </motion.div>
          </AnimatePresence>
        ))}
      </S.ProgressBarContainer>
    </S.BenchmarkBar>
  );
};

export default BenchmarkBar;
