import { useCallback, useState } from 'react';
import isNonEmptyString from 'src/utils/isNonEmptyString';
import { useDebounce } from 'src/utils/useDebounce';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { EducationalResource, generateEducationalResource } from '../utils';

import useApiRequest from './useApiRequest';

export const INITIAL_NUMBER_OF_LISTED_EDUCATIONAL_RESOURCES = 20;
export const MAX_NUMBER_OF_EDUCATIONAL_RESOURCES_TO_FETCH_NEXT = 20;

interface Filters {
  completed?: boolean;
  isNew?: boolean;
  isPopular?: boolean;
  searchValue?: string;
  selectedAgeGroups?: string[];
  selectedCommunities?: string[];
  selectedEducationTypes?: string[];
  selectedProfession?: string;
  selectedSpecialty?: string;
  viewAssignedEducationIsSelected?: boolean;
  viewBookmarksIsSelected?: boolean;
}

type FetchEducationalResources = (
  filters?: Filters,
  skipSettingIsFetching?: boolean,
  numberOfListedEducationalResources?: number
) => void;

type RefetchEducationalResources = FetchEducationalResources;

interface UseGetEducationalResources {
  (filters?: Filters): {
    educationalResources: EducationalResource[];
    hasFetchedAll: boolean;
    isFetching: boolean;
    isFetchingMore: boolean;
    refetchEducationalResources: RefetchEducationalResources;
    updateFilters: (filters: Filters) => void;
  };
}

const useGetEducationalResources: UseGetEducationalResources = filters => {
  const { getRequest, reportError } = useApiRequest();

  const [educationalResources, setEducationalResources] = useState<EducationalResource[]>([]);
  const [hasFetchedAll, setHasFetchedAll] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [query, setQuery] = useState<Filters>(filters ?? {});

  const fetchEducationalResources: FetchEducationalResources = useCallback(
    async (
      query,
      skipSettingIsFetching = false,
      numberOfListedEducationalResources = INITIAL_NUMBER_OF_LISTED_EDUCATIONAL_RESOURCES
    ) => {
      if (!skipSettingIsFetching) {
        setIsFetching(true);
      }

      if (numberOfListedEducationalResources !== INITIAL_NUMBER_OF_LISTED_EDUCATIONAL_RESOURCES) {
        setIsFetchingMore(true);
      }

      let url: RequestInfo;

      /*
      TODO: would love to refactor the filters here
      so we can simply convert an object to query params
      like we do in useGetCollections
    */
      if (query) {
        const {
          completed,
          isNew = false,
          isPopular = false,
          searchValue,
          selectedAgeGroups,
          selectedCommunities,
          selectedEducationTypes,
          selectedProfession,
          selectedSpecialty,
          viewAssignedEducationIsSelected = false,
          viewBookmarksIsSelected = false
        } = query;

        let queryString = '';

        if (isNonEmptyString(searchValue)) {
          queryString += `short_title=${searchValue}&`;
        }

        if (selectedCommunities && selectedCommunities.length !== 3) {
          selectedCommunities.forEach(selectedCommunity => {
            queryString += `communities[]=${selectedCommunity.toLowerCase()}_communities&`;
          });
        }

        if (isNonEmptyString(selectedProfession)) {
          queryString += `professional_groups[]=${selectedProfession}&`;
        }

        if (isNonEmptyString(selectedSpecialty)) {
          queryString += `specialties[]=${selectedSpecialty}&`;
        }

        if (selectedEducationTypes && selectedEducationTypes.length !== 5) {
          selectedEducationTypes.forEach(selectedEducationType => {
            if (selectedEducationType === 'Courses') {
              queryString += 'format_category[]=course&';
            } else if (selectedEducationType === 'Publications') {
              queryString += 'format_category[]=publication&';
            } else if (selectedEducationType === 'Guides') {
              queryString += 'format_category[]=guide&';
            } else if (selectedEducationType === 'Case vignettes') {
              queryString += 'format_category[]=case_vignette&';
            } else if (selectedEducationType === 'Other') {
              queryString += 'format_category[]=discussion&';
            }
          });
        }

        if (selectedAgeGroups && selectedAgeGroups.length !== 2) {
          selectedAgeGroups.forEach(selectedAgeGroup => {
            if (selectedAgeGroup === 'Child & adolescent') {
              queryString += 'age_groups[]=Infant&age_groups[]=Child&age_groups[]=Adolescent&';
            } else if (selectedAgeGroup === 'Adult') {
              queryString += `age_groups[]=Adult&age_groups[]=${encodeURIComponent(
                'Older Adult'
              )}&age_groups[]=&`;
            }
          });
        }

        if (viewBookmarksIsSelected) {
          queryString += 'bookmarked=true&';
        }

        if (viewAssignedEducationIsSelected) {
          queryString += 'assigned=true&';
        }

        if (isNew) {
          queryString += 'is_new=true&';
        }

        if (isPopular) {
          queryString += 'is_popular=true&';
        }

        if (completed !== undefined) {
          queryString += `completed=${completed}&`;
        }

        url = `${
          process.env.REACT_APP_API_V2_BASE_PATH
        }/courses?per_page=${numberOfListedEducationalResources}${
          queryString === '' ? '' : `&${queryString.slice(0, -1)}`
        }`;
      } else {
        url = `${process.env.REACT_APP_API_V2_BASE_PATH}/courses?per_page=${numberOfListedEducationalResources}`;
      }

      try {
        const { data, meta } = (await getRequest(url)) as {
          data?: APICourses['data'];
          meta?: APICourses['meta'];
        };

        const educationalResources =
          data !== undefined ? data.map(generateEducationalResource) : [];

        setEducationalResources(educationalResources);
        setHasFetchedAll(
          meta !== undefined ? educationalResources.length === meta.total_count : true
        );
      } catch (error) {
        reportError(error);
      } finally {
        if (!skipSettingIsFetching) {
          setIsFetching(false);
        }
        if (numberOfListedEducationalResources !== INITIAL_NUMBER_OF_LISTED_EDUCATIONAL_RESOURCES) {
          setIsFetchingMore(false);
        }
      }
    },
    [getRequest, reportError]
  );

  const debouncedFetchCourses = useDebounce(fetchEducationalResources, 200);

  const refetchEducationalResources: RefetchEducationalResources = useCallback(
    (
      query,
      skipSettingIsFetching = false,
      numberOfListedEducationalResources = INITIAL_NUMBER_OF_LISTED_EDUCATIONAL_RESOURCES
    ) => {
      debouncedFetchCourses(query, skipSettingIsFetching, numberOfListedEducationalResources);
    },
    [debouncedFetchCourses]
  );

  const updateFilters = (filters: Filters) => {
    setQuery(filters);
  };

  useDeepCompareEffect(() => {
    setIsFetching(true);
    debouncedFetchCourses(query);

    return () => {
      setEducationalResources([]);
      setHasFetchedAll(false);
      setIsFetching(false);
      setQuery({});
    };
  }, [debouncedFetchCourses, query]);

  return {
    educationalResources,
    hasFetchedAll,
    isFetching,
    isFetchingMore,
    refetchEducationalResources,
    updateFilters
  };
};

export default useGetEducationalResources;
