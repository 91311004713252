import * as Sentry from '@sentry/react';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import snakeCase from 'lodash/snakeCase';
import transform from 'lodash/transform';
import { useCallback } from 'react';
import useOpenErrorModalDialog from 'src/hooks/useOpenErrorModalDialog';
import useOpenForbiddenModalDialog from 'src/hooks/useOpenForbiddenModalDialog';
import useOpenSignedOutModalDialog from 'src/hooks/useOpenSignedOutModalDialog';
import useUser from 'src/hooks/useUser';
import isNonEmptyString from 'src/utils/isNonEmptyString';

const apiOptions = (bearerToken: string) =>
  ({
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json'
    }
  }) as RequestInit;

// eslint-disable-next-line @typescript-eslint/ban-types
export const filtersToParams = (filters: Object) => {
  const params = new URLSearchParams();

  Object.keys({ ...filters }).forEach(key => {
    if (!key) {
      return;
    }
    const filterKey = key as keyof typeof filters;
    const filterValue = filters[filterKey];

    if (
      (typeof filterValue === 'string' || filterValue instanceof String) &&
      isNonEmptyString(filterValue)
    ) {
      // column: 'value' => column=value
      params.append(key, String(filterValue));
    } else if (typeof filterValue === 'boolean' || typeof filterValue === 'number') {
      // column: true => column=true
      // column: 1 => column=1
      params.append(key, String(filterValue));
    } else if (Array.isArray(filterValue) && filterValue.length >= 1) {
      // 'column[]': [1,2] => column[]=1&column[]=2
      // column: [1,2] => column=1&column=2
      filterValue.forEach((item: string) => params.append(key, item));
    } else if (typeof filterValue === 'object') {
      // column: { subcolumn: value } => column[subcolumn]=value
      Object.keys(filterValue).forEach(subKey => {
        const subFilterKey = subKey as keyof typeof filterValue;
        const subFilterValue = filterValue[subFilterKey];
        params.append(`${filterKey}[${String(subFilterKey)}]`, String(subFilterValue));
      });
    }
  });

  return params.toString().replaceAll('%5B', '[').replaceAll('%5D', ']');
};

export const snakeCaseKeys = (obj: object) =>
  transform(obj, (acc: Record<string, unknown>, value, key: string, target) => {
    const snakeKey = isArray(target) ? key : snakeCase(key);

    acc[snakeKey] = isObject(value) ? snakeCase(value) : value;
  });

const useApiRequest = () => {
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const openForbiddenModalDialog = useOpenForbiddenModalDialog();
  const { bearerToken } = useUser();

  const reportError = useCallback(
    (error: unknown) => {
      Sentry.captureException(error);
      console.error(error);
      openErrorModalDialog();
    },
    [openErrorModalDialog]
  );

  const validateResponse = useCallback(
    (response: Response) => {
      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else if (response.status === 403) {
          openForbiddenModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }
    },
    [openSignedOutModalDialog, openForbiddenModalDialog]
  );

  const getRequest = useCallback(
    async (url: string) => {
      try {
        const response = await fetch(url, apiOptions(bearerToken));

        validateResponse(response);

        return await response.json();
      } catch (error) {
        reportError(error);
        return { data: undefined, meta: undefined };
      }
    },
    [bearerToken, reportError, validateResponse]
  );

  const postRequest = useCallback(
    async (url: string, requestBody: FormData | object) => {
      try {
        const options = apiOptions(bearerToken);
        const isFormData = requestBody instanceof FormData;

        if (isFormData && options.headers !== undefined) {
          delete (options.headers as Record<string, string>)['Content-Type'];
        }

        const response = await fetch(url, {
          body: isFormData ? requestBody : JSON.stringify(requestBody),
          method: 'POST',
          ...options
        });

        validateResponse(response);

        return response.status !== 204 ? await response.json() : response.ok;
      } catch (error) {
        reportError(error);
      }
    },
    [bearerToken, reportError, validateResponse]
  );

  const patchRequest = useCallback(
    async (url: string, requestBody: FormData | object) => {
      try {
        const options = apiOptions(bearerToken);
        const isFormData = requestBody instanceof FormData;

        if (isFormData && options.headers !== undefined) {
          delete (options.headers as Record<string, string>)['Content-Type'];
        }

        const response = await fetch(url, {
          body: isFormData ? requestBody : JSON.stringify(requestBody),
          method: 'PATCH',
          ...options
        });

        validateResponse(response);

        return response.status !== 204 ? await response.json() : response.ok;
      } catch (error) {
        reportError(error);
      }
    },
    [bearerToken, reportError, validateResponse]
  );

  const deleteRequest = useCallback(
    async (url: string) => {
      try {
        const response = await fetch(url, {
          method: 'DELETE',
          ...apiOptions(bearerToken)
        });

        validateResponse(response);

        return response.status !== 204 ? await response.json() : response.ok;
      } catch (error) {
        reportError(error);
      }
    },
    [bearerToken, reportError, validateResponse]
  );

  return { deleteRequest, getRequest, patchRequest, postRequest, reportError };
};

export default useApiRequest;
