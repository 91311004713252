import { ReactComponent as AddCircleIcon } from '@material-design-icons/svg/round/add_circle.svg';
import { ReactComponent as DeleteIcon } from '@material-design-icons/svg/round/delete.svg';
import { ReactComponent as EditPencilIcon } from '@material-design-icons/svg/round/edit.svg';
import { ReactComponent as ExpandLessIcon } from '@material-design-icons/svg/round/expand_less.svg';
import { ReactComponent as ExpandMoreIcon } from '@material-design-icons/svg/round/expand_more.svg';
import { useEffect, useState } from 'react';
import GroupCheckbox from 'src/components/FormFields/CheckboxGroup/GroupCheckbox';
import { PURPLE_600_COLOR } from 'src/components/GlobalStyle';
import PopoverTrigger from 'src/components/PopoverTrigger';
import NumberField from 'src/components/Table/TableNumberField';
import TextField from 'src/components/Table/TableTextField';
import { BREAKPOINT } from 'src/constants';
import useBreakpointRange from 'src/hooks/useBreakpointRange';
import { Education } from 'src/pages/Dashboard/utils';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import { CURRENT_YEAR } from '../../../constants';
import { isInvalidNumber } from '../useEducationsContext';

import * as S from './styles';

interface Props {
  education: Education;
  handleExpandCollapseClick: (index: number) => void;
  index: number;
  isExpanded: boolean;
  isInvalid: boolean;
  onRemove: (index: number) => void;
  openCertificateModalDialog: (education: Education) => void;
  updateEducation: (index: number, updatedEducation: Education) => void;
}

const EducationEntryRow = ({
  education,
  handleExpandCollapseClick,
  index,
  isExpanded,
  isInvalid,
  onRemove,
  openCertificateModalDialog,
  updateEducation
}: Props) => {
  const [communityCheckboxSelections, setCommunityCheckboxSelections] = useState<string[]>();
  const { isInDesktopBreakpointRange, isInMobileBreakpointRange } = useBreakpointRange();
  const BodyElementType = window.innerWidth < BREAKPOINT ? S.BodyFieldElement : S.BodyCell;

  const handleCourseAttachmentButtonPress = (education: Education) => {
    openCertificateModalDialog(education);
  };

  const handleCreditsChange = (value: number) => {
    education.credits = value;
    updateEducation(index, education);
  };

  const handleDeleteButtonPress = () => {
    onRemove(index);
  };

  const handleNameChange = (value: string) => {
    education.courseName = value;
    updateEducation(index, education);
  };

  const handleCourseOrganizationChange = (value: string) => {
    education.organization = value;
    updateEducation(index, education);
  };

  const handleCourseYearChange = (value: number) => {
    education.year = value;
    updateEducation(index, education);
  };

  const handleSelectedCommunitiesChange = (value: string[]) => {
    education.bipocTag = value.includes('bipoc');
    education.lgbqTag = value.includes('lgbq');
    education.tgncTag = value.includes('tgnc');
    education.communities = value;
    updateEducation(index, education);
  };

  useEffect(() => {
    const values = [];
    if (education.bipocTag) values.push('bipoc');
    if (education.lgbqTag) values.push('lgbq');
    if (education.tgncTag) values.push('tgnc');
    setCommunityCheckboxSelections(values);
  }, [education.bipocTag, education.lgbqTag, education.tgncTag]);

  const communityCheckboxes = (
    <S.CheckboxGroup
      aria-label="Communities of focus"
      data-cy="education-communities-field"
      direction="horizontal"
      isReadOnly={education.courseId !== null}
      onChange={handleSelectedCommunitiesChange}
      size="small"
      value={communityCheckboxSelections}
    >
      <GroupCheckbox value="bipoc">BIPOC</GroupCheckbox>
      <GroupCheckbox value="lgbq">LGBQ</GroupCheckbox>
      <GroupCheckbox value="tgnc">TGNC</GroupCheckbox>
    </S.CheckboxGroup>
  );

  const organizationNameField = (
    <TextField
      aria-label="Organization*"
      data-cy="education-organization-field"
      data-hj-allow
      displayTooltip
      errorMessage={
        !isNonEmptyString(education.organization) && isInvalid
          ? 'Please fill out this field.'
          : undefined
      }
      isInvalid={!isNonEmptyString(education.organization) && isInvalid}
      isReadOnly={education.courseId !== null}
      isRequired
      label={isInMobileBreakpointRange ? 'Organization' : undefined}
      onChange={handleCourseOrganizationChange}
      placeholder="Organization name*"
      validationBehavior="aria"
      value={education.organization}
    />
  );

  const yearField = (
    <NumberField
      aria-label="Year*"
      data-cy="education-year-field"
      data-hj-allow
      errorMessage={
        isInvalidNumber(Number(education.year)) && isInvalid
          ? 'Please fill out this field.'
          : undefined
      }
      formatOptions={{ useGrouping: false }}
      isInvalid={isInvalidNumber(education.year) && isInvalid}
      isReadOnly={education.courseId !== null}
      isRequired
      label={isInMobileBreakpointRange ? 'Year' : undefined}
      maxValue={CURRENT_YEAR}
      minValue={1960}
      onChange={handleCourseYearChange}
      placeholder="YYYY*"
      validationBehavior="aria"
      value={education.year}
    />
  );

  const creditHoursField = (
    <NumberField
      aria-label="Credits/Hours*"
      data-cy="education-credits-field"
      data-hj-allow
      errorMessage={
        isInvalidNumber(Number(education.credits)) && isInvalid
          ? 'Please fill out this field.'
          : undefined
      }
      formatOptions={{ useGrouping: false }}
      isInvalid={isInvalidNumber(Number(education.credits)) && isInvalid}
      isReadOnly={education.courseId !== null}
      isRequired
      label={isInMobileBreakpointRange ? 'Credits/hours' : undefined}
      maxValue={150}
      minValue={0.25}
      onChange={handleCreditsChange}
      placeholder="Credits*"
      step={0.25}
      validationBehavior="aria"
      value={Number(education.credits)}
    />
  );

  return (
    <>
      <BodyElementType
        $area="expand-collapse"
        $center
        $mobileAlignment="left"
        className="header-cell"
        role="gridcell"
      >
        {isInMobileBreakpointRange && (
          <S.ExpandButton.Root
            aria-label={isExpanded ? 'Collapse' : 'Expand'}
            data-cy="expand-row-button"
            isDisabled={isInvalid}
            onPress={() => handleExpandCollapseClick(index)}
          >
            <S.ExpandButton.Icon
              aria-hidden="true"
              as={isExpanded || isInvalid ? ExpandLessIcon : ExpandMoreIcon}
              role="img"
            />
            {isExpanded || isInvalid ? 'Collapse' : 'Expand'}
          </S.ExpandButton.Root>
        )}
      </BodyElementType>
      <BodyElementType
        $area="education-name"
        role="gridcell"
      >
        <TextField
          aria-label="Course name*"
          autoFocus={education.courseId !== null && education.courseName === ''}
          data-cy="education-name-field"
          data-hj-allow
          displayTooltip
          errorMessage={
            !education.courseName.trim() && isInvalid ? 'Please fill out this field.' : undefined
          }
          isInvalid={!education.courseName.trim() && isInvalid}
          isReadOnly={education.courseId !== null}
          isRequired
          label={isInMobileBreakpointRange ? 'Course name' : undefined}
          onChange={handleNameChange}
          placeholder="Course name*"
          value={education.courseName}
        />
      </BodyElementType>
      {isInDesktopBreakpointRange && (
        <BodyElementType
          $area="organization"
          role="gridcell"
        >
          {organizationNameField}
        </BodyElementType>
      )}
      {isInDesktopBreakpointRange && (
        <BodyElementType
          $area="year"
          role="gridcell"
        >
          {yearField}
        </BodyElementType>
      )}
      {isInDesktopBreakpointRange && (
        <BodyElementType
          $area="credits"
          role="gridcell"
        >
          {creditHoursField}
        </BodyElementType>
      )}
      {isInDesktopBreakpointRange && (
        <BodyElementType
          $area="attachment"
          role="gridcell"
        >
          {communityCheckboxes}
        </BodyElementType>
      )}
      {isInDesktopBreakpointRange && (
        <BodyElementType
          $area="attachment"
          role="gridcell"
        >
          {education.courseId === null &&
            (education.image !== null &&
            (education.image instanceof File || education.image.url !== null) ? (
              <S.EditAttachmentButton
                data-cy="edit-attachment-button"
                onPress={() => handleCourseAttachmentButtonPress(education)}
              >
                <S.EditIcon />
              </S.EditAttachmentButton>
            ) : (
              <S.AddAttachmentButton
                data-cy="add-attachment-button"
                onPress={() => handleCourseAttachmentButtonPress(education)}
              >
                <S.AddIcon />
              </S.AddAttachmentButton>
            ))}
        </BodyElementType>
      )}
      <BodyElementType
        $area="delete"
        className="header-cell"
        role="gridcell"
      >
        {education.courseId === null && (
          <S.DeleteButton
            data-cy="delete-education-button"
            onPress={() => handleDeleteButtonPress()}
          >
            <DeleteIcon fill={PURPLE_600_COLOR} />
          </S.DeleteButton>
        )}
      </BodyElementType>
      <BodyElementType
        $area="collapse-content"
        $center={false}
        role="gridcell"
      >
        {isInMobileBreakpointRange && (isExpanded || isInvalid) && (
          <S.MobileExpandedContainer>
            {organizationNameField}
            <S.MobileFieldRow>
              {yearField}
              {creditHoursField}
            </S.MobileFieldRow>
            <div>
              <S.CommunityMobileLabel>
                <PopoverTrigger
                  content={
                    <S.PopoverContent>
                      Please indicate which communities were the primary focus in your coursework or
                      training. If your education did not focus on BIPOC, LGBQ or TGNC communities,
                      you do not need to include it.
                    </S.PopoverContent>
                  }
                >
                  <S.PopoverButton>
                    <S.CheckboxGroupMobileLabel>Communties of focus</S.CheckboxGroupMobileLabel>
                    <S.HelpIcon />
                  </S.PopoverButton>
                </PopoverTrigger>
              </S.CommunityMobileLabel>
              {communityCheckboxes}
            </div>
            <div>
              {education.courseId === null &&
                (education.image !== null &&
                (education.image instanceof File || education.image.url !== null) ? (
                  <S.MobileAttachmentButton
                    data-cy="edit-attachment-button"
                    onPress={() => handleCourseAttachmentButtonPress(education)}
                    trailingIcon={EditPencilIcon}
                  >
                    Edit attachment
                  </S.MobileAttachmentButton>
                ) : (
                  <S.MobileAttachmentButton
                    data-cy="add-attachment-button"
                    onPress={() => handleCourseAttachmentButtonPress(education)}
                    trailingIcon={AddCircleIcon}
                  >
                    Add attachment
                  </S.MobileAttachmentButton>
                ))}
            </div>
          </S.MobileExpandedContainer>
        )}
      </BodyElementType>
    </>
  );
};

export default EducationEntryRow;
