import { useMemo } from 'react';

import * as S from './styles';

interface Props {
  communities: string[];
  community: string;
}

const CommunityCheck = ({ communities, community }: Props) => {
  const isInCommunity = useMemo(
    () => communities.some(c => c.toUpperCase() === community.toUpperCase()),
    [communities, community]
  );

  return isInCommunity ? (
    <S.CheckedIcon
      aria-label="checked"
      data-cy={`course-${community.toLowerCase()}-checked`}
      role="img"
    />
  ) : (
    <S.UnCheckedIcon
      aria-label="unchecked"
      data-cy={`course-${community.toLowerCase()}-unchecked`}
      role="img"
    />
  );
};

export default CommunityCheck;
