import { ReactComponent as DownloadIcon } from '@material-design-icons/svg/round/download.svg';
import Button from 'src/components/Buttons/Button';
import { CourseCollectionCompletion } from 'src/pages/Dashboard/utils';

import * as S from './styles';

interface Props {
  collection: CourseCollectionCompletion['collection'];
}

const CollectionActionButton = ({ collection }: Props) => {
  const hasCertificate = collection.certificates.length > 0;
  if (collection.isAccredited && collection.canBeEvaluated) {
    return (
      <Button to={`/dashboard/education/collections/${collection.id}/accreditation-survey`}>
        Claim CE/CME credit
      </Button>
    );
  }

  if (collection.isAccredited && collection.evaluation && !hasCertificate) {
    return (
      <Button to={`/dashboard/education/collections/${collection.id}/accreditation-certificate`}>
        Generate certificate
      </Button>
    );
  }

  if (collection.isAccredited && collection.evaluation && hasCertificate) {
    return (
      <Button
        href={`${process.env.REACT_APP_BASE_PATH}${collection.certificates[0]?.file.url}`}
        target="_blank"
        trailingIcon={DownloadIcon}
      >
        Download
      </Button>
    );
  }

  return <S.NotAvailableText>Not available</S.NotAvailableText>;
};

export default CollectionActionButton;
