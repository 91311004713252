import { RefObject } from 'react';
import { Location } from 'react-router-dom';

import { LinkList } from '..';
import { isExactPathMatch, isPartialPathMatch } from '../utils';

import * as S from './styles';

interface Props {
  linkList: LinkList[];
  linksRef?: RefObject<HTMLDivElement>;
  location: Location;
  selectedParentLink: LinkList | null;
}

const DesktopSecondaryNavigation = ({
  linkList,
  linksRef,
  location,
  selectedParentLink
}: Props) => {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    const currentElement = event.target as HTMLElement;
    const currentNavIndex = currentElement.getAttribute('data-nav-index');
    const currentNavIndexNumber = Number(currentNavIndex);

    if (event.key === 'ArrowLeft') {
      const prevNavIndex = currentNavIndexNumber - 1;
      const prevNavElement = document.querySelector(`[data-nav-index="${prevNavIndex}"]`);
      (prevNavElement as HTMLElement | null)?.focus();
    } else if (event.key === 'ArrowRight') {
      const nextNavIndex = currentNavIndexNumber + 1;
      const nextNavElement = document.querySelector(`[data-nav-index="${nextNavIndex}"]`);
      (nextNavElement as HTMLElement | null)?.focus();
    }
  };

  let navIndex = 0;

  return (
    <S.DesktopSecondaryNavigationWrapper ref={linksRef}>
      <S.SecondaryNavigationBar
        aria-label="Desktop Secondary Navigation"
        aria-orientation="horizontal"
        data-cy="secondary-nav-links"
        onKeyDown={handleKeyDown}
        role="menubar"
      >
        {linkList.map(link => {
          if (link.hideCondition !== undefined && link.hideCondition) return null;
          navIndex++;
          return (
            <S.ParentLinkWrapper
              key={link.link}
              className={`parent-wrapper ${selectedParentLink?.link === link.link ? 'is-selected' : ''}`}
            >
              <S.ParentLink
                data-label={link.title}
                data-nav-index={navIndex}
                onClick={link.onClick}
                role="menuitem"
                tabIndex={0}
                to={link.link}
              >
                {link.title}
              </S.ParentLink>
              {link.children && link.children.length > 0 && (
                <S.ChildNavigation
                  className={`child-nav ${selectedParentLink?.link === link.link ? 'active-child-nav' : ''}`}
                >
                  <S.ChildNavigationWrapper data-cy="children-nav">
                    {link.children.map(child => {
                      navIndex++;
                      const isExact = child.exact !== undefined ? child.exact : false;
                      if (child.hideCondition !== undefined && child.hideCondition) return null;
                      return (
                        <S.ChildLinkWrapper
                          key={child.link}
                          className={
                            (isExact &&
                              isExactPathMatch(
                                location.pathname,
                                link.parentLink ?? '',
                                child.link
                              )) ||
                            (!isExact &&
                              isPartialPathMatch(
                                location.pathname,
                                link.parentLink ?? '',
                                child.link
                              ))
                              ? 'is-selected'
                              : undefined
                          }
                        >
                          <S.ChildLink
                            data-label={child.title}
                            data-nav-index={navIndex}
                            onClick={child.onClick}
                            role="menuitem"
                            tabIndex={0}
                            to={`${link.parentLink}${child.link}`}
                          >
                            {child.title}
                          </S.ChildLink>
                        </S.ChildLinkWrapper>
                      );
                    })}
                  </S.ChildNavigationWrapper>
                </S.ChildNavigation>
              )}
            </S.ParentLinkWrapper>
          );
        })}
      </S.SecondaryNavigationBar>
    </S.DesktopSecondaryNavigationWrapper>
  );
};

export default DesktopSecondaryNavigation;
