import { ReactComponent as ExpandLessIcon } from '@material-design-icons/svg/round/expand_less.svg';
import { ReactComponent as ExpandMoreIcon } from '@material-design-icons/svg/round/expand_more.svg';
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import useBreakpointRange from 'src/hooks/useBreakpointRange';

import BenchmarkBar from '../BenchmarkBar';
import Button from '../Buttons/Button';

import * as S from './styles';

export interface BenchmarkObject {
  badges: {
    imageSrc: string;
    latestEarned: boolean;
    level: 'Awareness' | 'Excellence' | 'Proficiency';
    notYetAwarded: boolean;
  }[];
  communityAcronym: 'BIPOC' | 'LGBQ' | 'TGNC';
  earnedAtDate: string;
  score: number;
}

interface Props {
  benchmarks: BenchmarkObject[];
  isClinical: boolean;
}

const BenchmarkSection = ({ benchmarks, isClinical }: Props) => {
  const { isInDesktopBreakpointRange, isInMobileBreakpointRange } = useBreakpointRange();
  const [isExpanded, setIsExpanded] = useState(true);

  return !isClinical ? (
    <S.AboutBenchmarksText
      className="nonclinical"
      data-cy="non-clinical-benchmark-text"
    >
      We know that half of the American population will be culturally diverse by 2045. To promote
      health equity, it’s essential for all health care professionals to take the necessary steps to
      deliver culturally competent care at every stage of a patient’s experience. Contribute to this
      goal by learning with Violet.
    </S.AboutBenchmarksText>
  ) : (
    <S.BenchmarksContainer>
      <S.Subtitle>
        Benchmarks
        {isInMobileBreakpointRange && (
          <S.ExpandButton
            aria-label={isExpanded ? 'Hide benchmarks' : 'Show benchmarks'}
            onPress={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </S.ExpandButton>
        )}
      </S.Subtitle>
      <AnimatePresence>
        {(isExpanded || isInDesktopBreakpointRange) && (
          <S.BenchmarkBlock
            key="benchmarks-block"
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            initial={{ height: 0, opacity: 0 }}
            style={{ overflow: 'hidden' }}
            transition={{ duration: 0.5 }}
          >
            <S.AboutBenchmarksText>
              Your Benchmarks are determined based on your work experience, education, lived
              experience, and confidence administering care within each community. You may increase
              your Benchmarks by adding more experience and completing Violet collections.{' '}
              <S.Link
                href="https://www.joinviolet.com/faq/benchmarks"
                rel="noreferrer"
                target="_blank"
              >
                Read more
              </S.Link>{' '}
              about Violet Benchmarks.
            </S.AboutBenchmarksText>
            {benchmarks.length === 0 ? (
              <S.GeneratingBenchmarksContainer data-cy="pending-benchmarks">
                <S.GearIcon
                  aria-hidden
                  role="img"
                />
                <S.GeneratingBenchmarkText>
                  We're currently reviewing your information and generating your Benchmarks. We'll
                  email you once this is complete.
                </S.GeneratingBenchmarkText>
              </S.GeneratingBenchmarksContainer>
            ) : (
              benchmarks.map(benchmark => (
                <BenchmarkBar
                  key={benchmark.communityAcronym}
                  badges={benchmark.badges}
                  communityAcronym={benchmark.communityAcronym}
                  earnedAtDate={benchmark.earnedAtDate}
                  score={benchmark.score}
                />
              ))
            )}
            <S.ButtonWrapper>
              <Button
                to="/dashboard/profile/work-experience"
                variant="plain"
              >
                Add experience
              </Button>
              <Button to="/dashboard/education/collections">Start learning</Button>
            </S.ButtonWrapper>
          </S.BenchmarkBlock>
        )}
      </AnimatePresence>
    </S.BenchmarksContainer>
  );
};

export default BenchmarkSection;
