import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import {
  FocusScope,
  OverlayContainer,
  useDialog,
  useModal,
  useOverlay,
  usePreventScroll
} from 'react-aria';
import { useParams } from 'react-router-dom';
import VioletConfetti from 'src/components/VioletConfetti';
import useGetCollection from 'src/pages/Dashboard/hooks/useGetCollection';
import { EducationalResource } from 'src/pages/Dashboard/utils';
import { useWindowSize } from 'usehooks-ts';

import useAnalytics from '../../../hooks/useAnalytics';
import useOpenErrorModalDialog from '../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../../hooks/useUser';
import MiniCourseCard from '../MiniCourseCard';

import * as S from './styles';

interface Props {
  close: () => void;
  educationalResource: EducationalResource;
  isFirstCompletedEducation: boolean;
  openBeginEvaluationModalDialog: (collectionId: string) => void;
  refetchEducationalResources: (skipSettingIsFetching?: boolean) => void;
}

const EducationCompletedModalDialog = ({
  close,
  educationalResource,
  isFirstCompletedEducation,
  openBeginEvaluationModalDialog,
  refetchEducationalResources
}: Props) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const feedbackFieldRef = useRef<HTMLTextAreaElement>(null);
  const [feedbackFieldValue, setFeedbackFieldValue] = useState('');

  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();

  const { bearerToken, user } = useUser();

  const [displayCelebrationAnimation, setDisplayCelebrationAnimation] =
    useState(isFirstCompletedEducation);
  const [displayNextCourse, setDisplayNextCourse] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setDisplayCelebrationAnimation(isFirstCompletedEducation);
  }, [isFirstCompletedEducation]);

  const handleCloseButtonPress = () => {
    close();

    if (displayNextCourse) {
      refetchEducationalResources(true);
    }
  };

  const { overlayProps, underlayProps } = useOverlay(
    {
      isDismissable: true,
      isOpen: true,
      onClose: isSubmitting ? undefined : handleCloseButtonPress
    },
    dialogRef
  );

  usePreventScroll();
  const { modalProps } = useModal();
  const { dialogProps, titleProps } = useDialog({}, dialogRef);
  const { pathwayId } = useParams<{ pathwayId: string }>();

  const [rating, setRating] = useState<string>('');
  const unfinishedCollection = educationalResource.courseCollections.find(
    collection => !collection.is_completed
  );
  const readyToEvaluateCollection = educationalResource.courseCollections.find(
    collection => collection.is_completed && collection.is_accredited && !collection.is_evaluated
  );
  const { collection } = useGetCollection(unfinishedCollection ? unfinishedCollection.id : '');
  const [nextUnfinishedCourse, setNextUnfinishedCourse] = useState<EducationalResource>();
  const [userHasEvaluationPermission, setUserHasEvaluationPermission] = useState(false);
  const nextCoursePath =
    pathwayId !== undefined
      ? `/dashboard/education/pathways/${pathwayId}`
      : `/dashboard/education/collections/${collection?.id}`;

  useEffect(() => {
    const unfinishedCourse = collection?.courses.find(
      course => course.completionStatus === 'incomplete'
    );
    setNextUnfinishedCourse(unfinishedCourse);
  }, [collection]);

  useEffect(() => {
    const hasEvaluationPermission = user.permissions.includes('evaluations.create');
    setUserHasEvaluationPermission(hasEvaluationPermission);
  }, [user.permissions, user.is_clinical]);

  const handleRatingRadioGroupChange = (value: string) => {
    setRating(value);
  };

  const handleFeedbackFieldChange = () => {
    if (feedbackFieldRef.current) {
      setFeedbackFieldValue(feedbackFieldRef.current.value);
    }
  };

  const handleSubmitButtonPress = async () => {
    const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/courses/${educationalResource.id}/rate`;

    const options: RequestInit = {
      body: JSON.stringify({
        course_rating: {
          ...(feedbackFieldRef.current &&
            feedbackFieldRef.current.value !== '' && { comment: feedbackFieldRef.current.value }),
          rating: Number(rating)
        }
      }),
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    };

    setIsSubmitting(true);

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      analytics?.track('Education | rating submitted', {
        education_resource_id: educationalResource.id
      });

      setIsSubmitting(false);

      if (unfinishedCollection) {
        setDisplayNextCourse(true);
      } else {
        close();

        if (readyToEvaluateCollection && userHasEvaluationPermission) {
          openBeginEvaluationModalDialog(readyToEvaluateCollection.id);
        }

        refetchEducationalResources(true);
      }
    } catch (error) {
      Sentry.captureException(error);

      setIsSubmitting(false);

      close();
      openErrorModalDialog();
    }
  };

  const { analytics } = useAnalytics();
  const { height, width } = useWindowSize();

  return (
    <OverlayContainer>
      <S.EducationCompletedModalDialog
        {...underlayProps}
        data-cy="course-completed-modal"
      >
        {displayCelebrationAnimation && (
          <VioletConfetti
            height={height}
            width={width}
          />
        )}
        <FocusScope
          autoFocus
          contain
          restoreFocus
        >
          <S.Dialog
            {...dialogProps}
            {...modalProps}
            {...overlayProps}
            ref={dialogRef}
          >
            <S.CloseButton.Root
              isDisabled={isSubmitting}
              onPress={handleCloseButtonPress}
            >
              <S.CloseButton.Icon />
            </S.CloseButton.Root>
            {displayNextCourse && nextUnfinishedCourse ? (
              <>
                <S.NextCourseTitle>Thank you! Here's your next course:</S.NextCourseTitle>
                <MiniCourseCard
                  collectionId={collection?.id}
                  course={nextUnfinishedCourse}
                  nextCoursePath={nextCoursePath}
                />
                <S.ModalButton
                  data-cy="view-next-course"
                  to={`${nextCoursePath}/${nextUnfinishedCourse.id}`}
                >
                  View course
                </S.ModalButton>
              </>
            ) : (
              <>
                <S.Title {...titleProps}>Congratulations!</S.Title>
                {displayCelebrationAnimation && (
                  <S.LargeText>You just took the first step on your inclusive journey.</S.LargeText>
                )}
                <S.Text>
                  You’ve completed {educationalResource.title}. Before it is added to your profile,
                  please rate the education:
                </S.Text>
                <S.RadioGroup
                  aria-label="Rating"
                  data-cy="rating-radio-group"
                  onChange={handleRatingRadioGroupChange}
                  orientation="horizontal"
                  value={rating}
                >
                  <S.Radio value="1">☹️</S.Radio>
                  <S.Radio value="2">😞</S.Radio>
                  <S.Radio value="3">😐</S.Radio>
                  <S.Radio value="4">🙂</S.Radio>
                  <S.Radio value="5">😍</S.Radio>
                </S.RadioGroup>
                {rating && (
                  <>
                    <S.SubTitle {...titleProps}>Tell us more?</S.SubTitle>
                    <S.Text>
                      {Number(rating) <= 2
                        ? 'We’re always improving our education, so any additional feedback you can provide is helpful. (Required)'
                        : 'We’re always improving our education, so any additional feedback you can provide is helpful.'}
                    </S.Text>
                    <S.TextArea
                      ref={feedbackFieldRef}
                      aria-label="Feedback"
                      autoFocus
                      data-cy="rating-feedback"
                      isMultiline
                      isRequired={Number(rating) <= 2}
                      onChange={handleFeedbackFieldChange}
                      placeholder={Number(rating) <= 2 ? '*' : ''}
                    />
                  </>
                )}
                <S.Separator />
                <S.ModalButton
                  data-cy="submit-rating"
                  data-educational-resource-title={educationalResource.title}
                  isDisabled={rating === '' || (Number(rating) <= 2 && feedbackFieldValue === '')}
                  isLoading={isSubmitting}
                  onPress={handleSubmitButtonPress}
                  size="large"
                >
                  Submit
                </S.ModalButton>
              </>
            )}
          </S.Dialog>
        </FocusScope>
      </S.EducationCompletedModalDialog>
    </OverlayContainer>
  );
};

export default EducationCompletedModalDialog;
