import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'src/utils/useDebounce';
import { useIsFirstRender } from 'usehooks-ts';

import { CompletedEducationalResource, generateOrganizationEducation } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

type FetchOrganizationEducation = (filters: OrganizationEducationFilters) => Promise<void>;

interface OrganizationEducationFilters {
  'communities[]'?: string[] | null;
  date_range?: 'last_12_months' | 'last_30_days' | 'last_90_days' | 'year_to_date';
  is_clinical?: boolean;
  order_by?: {
    column: string | undefined;
    dir: 'asc' | 'desc' | undefined;
  };
  page?: number;
  per_page?: number;
  resource_name?: string;
  resource_type?: 'course_collection' | 'course';
  user_name?: string;
}

interface UseGetOrganizationEducation {
  (
    organizationId: string,
    filters: OrganizationEducationFilters
  ): {
    education: CompletedEducationalResource[];
    isFetchingOrgEducation: boolean;
    totalOrgEducationPages: number;
    updateEducationFilters: (newFilters: OrganizationEducationFilters) => void;
  };
}

const useGetOrganizationEducation: UseGetOrganizationEducation = (organizationId, filters) => {
  const { getRequest, reportError } = useApiRequest();
  const isFirstRender = useIsFirstRender();

  const [education, setEducation] = useState<CompletedEducationalResource[]>([]);
  const [isFetchingOrgEducation, setIsFetchingOrgEducation] = useState(false);
  const [query, setQuery] = useState<OrganizationEducationFilters>(filters);
  const [totalOrgEducationPages, setTotalOrgEducationPages] = useState(1);

  const fetchOrganizationEducation: FetchOrganizationEducation = useCallback(
    async filters => {
      if (!organizationId) return;

      setIsFetchingOrgEducation(true);

      const params = filtersToParams(filters);

      const url: RequestInfo = `${
        process.env.REACT_APP_API_V2_BASE_PATH
      }/organizations/${organizationId}/educations${params ? `?${params}` : ''}`;

      try {
        const { data, meta } = (await getRequest(url)) as {
          data?: APIUsersDashboardOrganizationsEducations['data'];
          meta?: APIUsersDashboardOrganizationsEducations['meta'];
        };

        setEducation(
          data !== undefined ? data.map(fields => generateOrganizationEducation(fields)) : []
        );
        if (meta !== undefined) {
          setTotalOrgEducationPages(Number(meta.total_pages));
        }
      } catch (error) {
        reportError(error);
      } finally {
        setIsFetchingOrgEducation(false);
      }
    },
    [getRequest, reportError, organizationId]
  );

  const debouncedFetchOrganizationEducation = useDebounce(fetchOrganizationEducation, 200);

  useEffect(() => {
    /*
      React StrictMode causes this initial fetch
      to happen twice even with no dependencies, resulting
      in a double API call w/ no filter changes.
      This is a workaround for now that will run the
      initial fetch only once after the component calling
      this hook has been mounted.
    */
    if (!isFirstRender) return;
    setIsFetchingOrgEducation(true);
    debouncedFetchOrganizationEducation(filters);

    return () => {
      setEducation([]);
      setIsFetchingOrgEducation(false);
      setQuery({});
    };
  }, [debouncedFetchOrganizationEducation, isFirstRender, filters]);

  const updateEducationFilters = (newFilters: Partial<OrganizationEducationFilters>) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof OrganizationEducationFilters] === undefined
        ? delete newFilters[key as keyof OrganizationEducationFilters]
        : {}
    );
    if (isEqual(newFilters, query)) return;
    setQuery(newFilters);
    debouncedFetchOrganizationEducation(newFilters);
  };

  return {
    education,
    isFetchingOrgEducation,
    totalOrgEducationPages,
    updateEducationFilters
  };
};

export default useGetOrganizationEducation;
