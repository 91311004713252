import { Temporal } from '@js-temporal/polyfill';
import { ToastState } from '@react-stately/toast';
import * as Sentry from '@sentry/react';
import { Dispatch, Key, SetStateAction } from 'react';
import { VioletToast } from 'src/components/ToastProvider';
import { filtersToParams } from 'src/pages/Dashboard/hooks/useApiRequest';
import { NetworkLocationsFilters } from 'src/pages/Dashboard/hooks/useGetNetworkLocations';
import { downloadCsv } from 'src/utils/downloadCsv';
import { prepareLocationsParam } from 'src/utils/prepareLocationParms';

import * as S from './styles';

interface Props {
  fetchOptions: RequestInit;
  searchParams: URLSearchParams;
  setIsExporting: Dispatch<SetStateAction<boolean>>;
  toastState: ToastState<VioletToast>;
  user: User;
}

export const mapLocationsFromKeysToAPIObject = (locations: Iterable<Key>) =>
  Array.from(locations).map(location => {
    const type = location.toString().split('|')[0];
    const childType = type.toLowerCase();
    if (childType === 'state') {
      return {
        [childType]: location.toString().split('|')[1]
      };
    } else {
      return {
        [childType]: location.toString().split('|')[1],
        state: location.toString().split('|')[2]
      };
    }
  });

export const useExportServiceAreas = ({
  fetchOptions,
  searchParams,
  setIsExporting,
  toastState,
  user
}: Props) => {
  const handleExportCSV = async () => {
    setIsExporting(true);

    const downloadingToastKey = toastState.add({
      cancelable: false,
      customIcon: (
        <S.ToastSpinner
          backgroundColor="#d1e9ff"
          color="#1570ef"
        />
      ),
      description: 'Your report is downloading.',
      type: 'info'
    });

    /* TODO: considerations
        - opt A) get the BE working to interpret locations as an array of objects correctly
        - opt B) refactor filtersToParams to support stringifing arrays of objects in specific cases
      */
    const paramsToSend = {
      'benchmark_communities[]': searchParams.get('benchmark_communities[]')?.split(',') ?? [],
      locations: mapLocationsFromKeysToAPIObject(searchParams.get('locations[]')?.split(',') ?? []),
      org_is_on_violet: searchParams.get('org_is_on_violet') === 'true' ? true : undefined,
      'organizations[]': searchParams.get('organizations[]')?.split(',') ?? [],
      'specialties[]': searchParams.get('specialties[]')?.split(',') ?? [],
      verified_inclusive: searchParams.get('verified_inclusive') === 'true' ? true : undefined,
      view: (searchParams.get('view') as NetworkLocationsFilters['view'] | undefined) ?? 'county'
    } as NetworkLocationsFilters;
    paramsToSend.locations = prepareLocationsParam(paramsToSend.locations);
    const params = filtersToParams(paramsToSend);

    const url: RequestInfo = `${process.env.REACT_APP_API_BASE_PATH}/networks/providers/locations/export${params ? `?${params}` : ''}`;

    try {
      const response = await fetch(url, fetchOptions);

      if (!response.ok) {
        throw new Error(`${response.status} (${response.statusText})`);
      }

      const membership = user.organization_memberships.find(
        membership => membership.member_role === 'network_manager'
      );
      if (!membership) {
        throw new Error('User does not have a network_manager role.');
      }
      const { organization } = membership;

      const formattedDate = Temporal.Now.plainDateISO()
        .toLocaleString('en-US')
        .replace(/[/]/g, '-');
      const organizationName = organization.name.replace(/\s/g, '');

      downloadCsv(response, `Violet_${organizationName}_ServiceAreaReport_${formattedDate}.csv`);

      toastState.close(downloadingToastKey);
      toastState.add(
        {
          description: 'Download complete.',
          type: 'success'
        },
        { timeout: 8000 }
      );
    } catch (error) {
      Sentry.captureException(error);
      toastState.close(downloadingToastKey);
      toastState.add(
        {
          description: 'Download failed.',
          type: 'error'
        },
        { timeout: 8000 }
      );
    }
    setIsExporting(false);
  };

  return {
    handleExportCSV
  };
};
