import isNonEmptyString from './isNonEmptyString';

export const getLocalStorageWithExpiration = (key: string) => {
  const localStorage = window.localStorage;
  const itemStr = localStorage.getItem(key);

  if (!isNonEmptyString(itemStr)) {
    return null;
  }

  const item = JSON.parse(itemStr === null ? '{}' : itemStr) as { expiry: number; value: string };
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

export const setLocalStorageWithExpiration = (key: string, value: string, ttl: number) => {
  const localStorage = window.localStorage;
  const now = new Date();

  const item = {
    expiry: now.getTime() + ttl,
    value
  };

  localStorage.setItem(key, JSON.stringify(item));
};
