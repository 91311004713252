import { ReactComponent as ArrowRightIcon } from '@material-design-icons/svg/round/arrow_forward.svg';
import { useLocalStorage } from 'usehooks-ts';

import Button from '../../../components/Buttons/Button';
import useUser from '../../../hooks/useUser';

import logoImageSrc from './images/logo.svg';
import * as S from './styles';

const Welcome = () => {
  const { user } = useUser();
  const [isHeadwayLms] = useLocalStorage<false | true>('is_headway_lms', false);

  const clinicalText =
    'First we’ll get to know you better by collecting information about your identity, education, and work experience for your profile. Once we have a clear picture of your background and interests, you’ll receive your Benchmarks and gain access to our full library of CE/CME accredited education.';

  return (
    <S.Page>
      <S.Layout>
        <S.Header>
          <S.LogoLink to="/onboarding/welcome">
            <S.Logotype
              color="light"
              size="regular"
            />
          </S.LogoLink>
        </S.Header>
        <S.Main>
          <S.LogoImage
            alt="Violet logo"
            src={logoImageSrc}
          />
          <S.Title>Welcome to Violet!</S.Title>
          {isHeadwayLms ? (
            <S.Paragraph data-cy="onboarding-welcome-text">
              Violet and Headway have partnered to offer providers free access to CE/CME accredited
              clinical training courses, in an effort to improve the health care experience for
              BIPOC and LGBTQIA+ communities.
              <br />
              <br />
              Before you obtain your credits, you’ll complete your Violet profile. We collect some
              information about your identity, education, and work experience to benchmark your
              cultural competencies. Once your profile is submitted, you’ll answer a few knowledge
              check questions and claim your CE/CME credit.
            </S.Paragraph>
          ) : (
            <S.Paragraph data-cy="onboarding-welcome-text">
              {user.is_clinical
                ? clinicalText
                : 'To get started, we’ll ask some questions to complete your profile.'}
            </S.Paragraph>
          )}
          <Button
            background="dark"
            data-cy="get-started-button"
            size="large"
            to="/onboarding/information"
            trailingIcon={ArrowRightIcon}
          >
            Get started
          </Button>
        </S.Main>
        <S.Footer>© Copyright Violet 2022. All Rights Reserved.</S.Footer>
      </S.Layout>
    </S.Page>
  );
};

export default Welcome;
