import capitalize from 'lodash/capitalize';
import { useEffect, useRef, useState } from 'react';
import { AriaCheckboxGroupProps, AriaSearchFieldProps } from 'react-aria';
import { useSearchParams } from 'react-router-dom';
import EducationFilters from 'src/components/Education/EducationFilters';
import PathwayCard from 'src/components/Education/PathwayCard';
import GroupCheckbox from 'src/components/FormFields/CheckboxGroup/GroupCheckbox';
import PageTitle from 'src/components/PageTitle';
import Spinner from 'src/components/Spinner';
import ObservedTarget from 'src/pages/components/ObservedTarget';
import { useUpdateEffect } from 'usehooks-ts';

import useBreakpointRange from '../../../../hooks/useBreakpointRange';
import useFilterAnalytics from '../../hooks/useFilterAnalytics';
import useGetPathways, { PathwaysFilters } from '../../hooks/useGetPathways';
import { Pathway } from '../../utils';
import EmptyState from '../components/EmptyState';
import LoadingState from '../components/LoadingState';
import Main from '../components/Main';

import * as S from './styles';

const PATHWAYS_PER_PAGE = 20;
const PROGRESS_STATUSES = ['not_started', 'in_progress', 'completed'];

const Pathways = () => {
  const { handleFilterAddedAnalytics } = useFilterAnalytics();
  const { isInDesktopBreakpointRange, isInMobileBreakpointRange } = useBreakpointRange();
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState(1);
  const [hasFetchedAll, setHasFetchedAll] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [allListedPathways, setAllListedPathways] = useState<Pathway[]>([]);
  const [isIntersecting, setIsIntersecting] = useState(false);

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [searchText, setSearchText] = useState(
    searchParams.get('name') !== null ? searchParams.get('name')! : ''
  );
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(
    searchParams.get('status') !== null ? searchParams.get('status')!.split(',') : []
  );

  const { isFetching, pathways, pathwaysTotalPages, updatePathwaysFilters } = useGetPathways({
    name: searchText,
    page,
    per_page: PATHWAYS_PER_PAGE,
    'status[]': selectedStatuses
  } as PathwaysFilters);

  const handleSearchValueChange: AriaSearchFieldProps['onChange'] = value => {
    setSearchText(value);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setSearchParams(searchParams => {
        if (value === '') {
          searchParams.delete('name');
        } else {
          searchParams.set('name', value);
        }
        searchParams.sort();
        return searchParams;
      });
    }, 500);
  };

  const handleSelectedStatusesChange: AriaCheckboxGroupProps['onChange'] = value => {
    const isAdded = value.length > selectedStatuses.length;
    if (isAdded) {
      handleFilterAddedAnalytics(
        'Pathways',
        'Status',
        value.filter(status => !selectedStatuses.includes(status)).map(status => capitalize(status))
      );
    }
    setSelectedStatuses(value);
    setSearchParams(searchParams => {
      if (value.length === 0) {
        searchParams.delete('status');
      } else {
        searchParams.set('status', value.join(','));
      }
      searchParams.sort();
      return searchParams;
    });
  };

  useUpdateEffect(() => {
    setAllListedPathways(prevPathways => {
      const newAndPrevPathways = [...prevPathways, ...pathways];
      return newAndPrevPathways.reduce((uniquePathways: Pathway[], currentPathway: Pathway) => {
        if (!uniquePathways.find(pathway => pathway.id === currentPathway.id)) {
          uniquePathways.push(currentPathway);
        }
        return uniquePathways;
      }, []);
    });
  }, [pathways]);

  useEffect(() => {
    setIsFetchingMore(false);
  }, [allListedPathways]);

  useUpdateEffect(() => {
    setSearchText(searchParams.get('name') !== null ? searchParams.get('name')! : '');
    setSelectedStatuses(
      searchParams.get('status') !== null ? searchParams.get('status')!.split(',') : []
    );
    setPage(1);
    setAllListedPathways([]);
  }, [searchParams]);

  useUpdateEffect(() => {
    setIsFetchingMore(true);
    updatePathwaysFilters({
      name: searchParams.get('name') ?? undefined,
      page,
      per_page: PATHWAYS_PER_PAGE,
      'status[]': searchParams.get('status')?.split(',') ?? undefined
    } as PathwaysFilters);
  }, [page, searchParams]);

  useUpdateEffect(() => {
    if (isIntersecting && !hasFetchedAll && page < pathwaysTotalPages) {
      setPage(page + 1);
    }
  }, [isIntersecting]);

  useUpdateEffect(() => {
    if (page >= pathwaysTotalPages) {
      setHasFetchedAll(true);
    } else {
      setHasFetchedAll(false);
    }
  }, [page, pathwaysTotalPages]);

  const pathwayTitle = (
    <PageTitle
      description="Learning pathways are curated roadmaps designed to help you develop specific skills. Whether your goal is to address a particular health disparity or better serve a specific community, these pathways provide structured guidance, enabling you to progress from foundational concepts to more advanced topics."
      title="Pathways"
      titleVariant="h1"
    />
  );

  const pathwayFilters = (
    <S.Filters.CheckboxGroup
      direction="vertical"
      label="Status"
      onChange={handleSelectedStatusesChange}
      value={selectedStatuses}
    >
      {PROGRESS_STATUSES.map(status => (
        <GroupCheckbox
          key={status.toLowerCase()}
          data-cy={`status-${status.toLowerCase()}`}
          value={status.toLowerCase()}
        >
          {capitalize(status.replaceAll('_', ' '))}
        </GroupCheckbox>
      ))}
    </S.Filters.CheckboxGroup>
  );

  return (
    <Main>
      {isInMobileBreakpointRange && pathwayTitle}
      <div>
        <EducationFilters
          filters={pathwayFilters}
          handleSearchValueChange={handleSearchValueChange}
          hideSearch
          searchTextValue={searchText}
        />
      </div>
      {isFetching ? (
        <LoadingState />
      ) : (
        <div>
          {isInDesktopBreakpointRange && pathwayTitle}
          {pathways.length === 0 && allListedPathways.length === 0 ? (
            <EmptyState />
          ) : (
            <S.Pathways>
              {allListedPathways.map(pathway => (
                <PathwayCard
                  key={pathway.id}
                  pathway={pathway}
                />
              ))}
            </S.Pathways>
          )}
          {!hasFetchedAll && <S.LoadingMore>{isFetchingMore && <Spinner />}</S.LoadingMore>}
          <ObservedTarget setIsIntersecting={setIsIntersecting} />
        </div>
      )}
    </Main>
  );
};

export default Pathways;
