import { ToastState } from '@react-stately/toast';
import * as Sentry from '@sentry/react';
import { produce } from 'immer';
import { FormEventHandler, Key, useEffect, useRef, useState } from 'react';
import { AriaCheckboxGroupProps } from 'react-aria';
import { useOutletContext } from 'react-router-dom';
import { Props as LanguageSearchProps } from 'src/components/FormFields/LanguageSearch';
import PageTitle from 'src/components/PageTitle';
import { VioletToast } from 'src/components/ToastProvider';
import { LANGUAGE_SEARCH_TEXT } from 'src/pages/strings';
import LanguageProficienciesData from 'src/utils/data/LanguageProficiencies';

import Button from '../../../../components/Buttons/Button';
import CheckboxGroup from '../../../../components/FormFields/CheckboxGroup';
import GroupCheckbox from '../../../../components/FormFields/CheckboxGroup/GroupCheckbox';
import useOpenErrorModalDialog from '../../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../../../hooks/useUser';

import * as S from './styles';

const allGenderIdentities = [
  'Cisgender woman',
  'Cisgender man',
  'Transgender woman',
  'Transgender man',
  'Non-binary',
  'Genderqueer',
  'Agender',
  'Intersex',
  'Prefer not to say'
] as const;

const allRaceEthnicities = [
  'Native American or Alaskan Native',
  'Asian',
  'Black or African American',
  'Hispanic or Latine/x',
  'Native Hawaiian or Pacific Islander',
  'White',
  'Middle Eastern or North African',
  'Prefer not to say'
] as const;

const allSexualOrientations = [
  'Heterosexual',
  'Gay',
  'Lesbian',
  'Bisexual',
  'Pansexual',
  'Queer',
  'Asexual',
  'Prefer not to say'
] as const;

const IdentityAndLanguages = () => {
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const { bearerToken, setUser, user } = useUser();
  const toastState = useOutletContext();

  const [raceEthnicities, setRaceEthnicities] = useState<string[]>(user.user_info.race_ethnicity);
  const [sexualOrientations, setSexualOrientations] = useState<string[]>(
    user.user_info.sexual_orientation
  );
  const [genderIdentities, setGenderIdentities] = useState<string[]>(
    user.user_info.gender_identity
  );
  const [selectedLanguages, setSelectedLanguages] = useState<
    { id?: string; language: Key | null; proficiency: Key }[]
  >([]);

  const languageProficienciesDataRef = useRef(new LanguageProficienciesData(bearerToken));

  const functionToCallAfterUserUpdateRef = useRef<() => void>();

  useEffect(() => {
    if (functionToCallAfterUserUpdateRef.current) {
      const functionToCallAfterUserUpdate = functionToCallAfterUserUpdateRef.current;
      functionToCallAfterUserUpdateRef.current = undefined;
      functionToCallAfterUserUpdate();
    }
  }, [user]);

  useEffect(() => {
    languageProficienciesDataRef.current.findAll().then(({ json, response }) => {
      if (!response.ok) {
        openErrorModalDialog();
      } else {
        setSelectedLanguages(json.data);
      }
    });
  }, [openErrorModalDialog]);

  const [formIsSubmitting, setFormIsSubmitting] = useState(false);

  const handleFormSubmit: FormEventHandler = async event => {
    event.preventDefault();

    setFormIsSubmitting(true);

    const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/profile`;

    const options: RequestInit = {
      body: JSON.stringify({
        user: {
          user_info_attributes: {
            gender_identity: genderIdentities,
            race_ethnicity: raceEthnicities,
            sexual_orientation: sexualOrientations
          }
        }
      }),
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PATCH'
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { data } = (await response.json()) as APIUsersDashboardGeneralInfo;

      const languageUpdateSuccess =
        await languageProficienciesDataRef.current.updateAll(selectedLanguages);

      if (!languageUpdateSuccess) {
        throw new Error('There was an issue saving languages.');
      }

      setUser(
        produce(user, draft => {
          draft.user_info.gender_identity = data.user_info.gender_identity;
          draft.user_info.languages = data.user_info.languages;
          draft.user_info.race_ethnicity = data.user_info.race_ethnicity;
          draft.user_info.sexual_orientation = data.user_info.sexual_orientation;
        })
      );

      functionToCallAfterUserUpdateRef.current = () => {
        window.scrollTo(0, 0);
        (toastState as ToastState<VioletToast>).add(
          {
            description: 'Your data has been saved.',
            type: 'success'
          },
          { timeout: 8000 }
        );
      };
    } catch (error) {
      (toastState as ToastState<VioletToast>).add(
        {
          description: 'Something went wrong. Please check for errors.',
          type: 'error'
        },
        { timeout: 8000 }
      );

      Sentry.captureException(error);
      openErrorModalDialog();
    }

    setFormIsSubmitting(false);
  };

  const handleRaceEthnicityChange: AriaCheckboxGroupProps['onChange'] = raceEthnicitySet => {
    setRaceEthnicities(raceEthnicitySet);
  };

  const handleGenderIdentitiesChange: AriaCheckboxGroupProps['onChange'] = genderIdentitySet => {
    setGenderIdentities(genderIdentitySet);
  };

  const handleSexualOrientationChange: AriaCheckboxGroupProps['onChange'] =
    sexualOrientationSet => {
      setSexualOrientations(sexualOrientationSet);
    };

  const handleLanguageSearchAddition: LanguageSearchProps['addLanguage'] = value => {
    const newSelection = selectedLanguages.concat(value);
    setSelectedLanguages(newSelection);
  };

  const handleLanguageSearchDeletion: LanguageSearchProps['deleteLanguage'] = value => {
    const newSelection = selectedLanguages.filter(item => item !== value);
    setSelectedLanguages(newSelection);
  };

  return (
    <>
      <PageTitle
        title="Identity and languages"
        titleVariant="h1"
      />
      <form onSubmit={handleFormSubmit}>
        <S.CheckboxGroupWrapper>
          <CheckboxGroup
            data-cy="race-ethnicity-field"
            direction="vertical"
            groupLabelColor="gray-800"
            isRequired
            label="Race/Ethnicity*"
            onChange={handleRaceEthnicityChange}
            size="regular"
            validationBehavior="native"
            value={raceEthnicities}
          >
            {allRaceEthnicities.map(raceEthnicity => (
              <GroupCheckbox
                key={raceEthnicity}
                name="selectedRaceEthnicities"
                value={raceEthnicity}
              >
                {raceEthnicity}
              </GroupCheckbox>
            ))}
          </CheckboxGroup>
          <CheckboxGroup
            data-cy="gender-identity-field"
            direction="vertical"
            groupLabelColor="gray-800"
            isRequired
            label="Gender identity*"
            onChange={handleGenderIdentitiesChange}
            size="regular"
            validationBehavior="native"
            value={genderIdentities}
          >
            {allGenderIdentities.map(genderIdentity => (
              <GroupCheckbox
                key={genderIdentity}
                name="selectedGenderIdentities"
                value={genderIdentity}
              >
                {genderIdentity}
              </GroupCheckbox>
            ))}
          </CheckboxGroup>
          <CheckboxGroup
            data-cy="sexual-orientation-field"
            direction="vertical"
            groupLabelColor="gray-800"
            isRequired
            label="Sexual orientation*"
            onChange={handleSexualOrientationChange}
            size="regular"
            validationBehavior="native"
            value={sexualOrientations}
          >
            {allSexualOrientations.map(sexualOrientation => (
              <GroupCheckbox
                key={sexualOrientation}
                name="selectedSexualOrientations"
                value={sexualOrientation}
              >
                {sexualOrientation}
              </GroupCheckbox>
            ))}
          </CheckboxGroup>
        </S.CheckboxGroupWrapper>
        <S.LanguageSearchSection
          addLanguage={handleLanguageSearchAddition}
          copy={
            user.is_clinical
              ? LANGUAGE_SEARCH_TEXT.copyClinical
              : LANGUAGE_SEARCH_TEXT.copyNonClinical
          }
          data-hj-allow
          deleteLanguage={handleLanguageSearchDeletion}
          header={
            user.is_clinical
              ? LANGUAGE_SEARCH_TEXT.titleClinical
              : LANGUAGE_SEARCH_TEXT.titleNonClinical
          }
          selectedLanguages={selectedLanguages}
        />
        <S.Buttons>
          <Button
            data-cy="save-button"
            isLoading={formIsSubmitting}
            size="large"
            type="submit"
          >
            Save
          </Button>
        </S.Buttons>
      </form>
    </>
  );
};

export default IdentityAndLanguages;
