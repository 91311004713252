import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'src/utils/useDebounce';

import { generateOrganizationOverview, OrganizationOverview } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';
import { OrganizationFilters } from './useGetOrganizationUsers';

type OrganizationOverviewFilters = Omit<
  OrganizationFilters,
  'benchmark_communities' | 'communities' | 'has_scored' | 'order_by' | 'page' | 'per_page'
> & {
  'communities[]'?: string[];
  'has_scored[]'?: string[];
};

type FetchOrganizationOverview = (
  filters?: OrganizationOverviewFilters,
  skipSettingIsFetching?: boolean
) => void;

type RefetchOrganizationOverview = FetchOrganizationOverview;

type UpdateOrganizationOverviewFilters = (newFilters: OrganizationOverviewFilters) => void;

interface UseGetOrganizationOverview {
  (
    filters?: OrganizationOverviewFilters,
    organizationId?: string
  ): {
    isFetching: boolean;
    organizationOverview: OrganizationOverview | undefined;
    refetchOrganizationOverview: RefetchOrganizationOverview;
    updateOrganizationOverviewFilters: UpdateOrganizationOverviewFilters;
  };
}

const useGetOrganizationOverview: UseGetOrganizationOverview = (
  filters = {},
  organizationId = ''
) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [organizationOverview, setOrganizationOverview] = useState<
    OrganizationOverview | undefined
  >();
  const [isFetching, setIsFetching] = useState(false);
  const [query, setQuery] = useState<OrganizationOverviewFilters>(filters);
  const { getRequest, reportError } = useApiRequest();

  const fetchOrganizationOverview: FetchOrganizationOverview = useCallback(
    async (filters = {}, skipSettingIsFetching = false) => {
      if (!organizationId) return;

      if (!skipSettingIsFetching) {
        setIsFetching(true);
      }

      const params = filtersToParams(filters);

      const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/organizations/${organizationId}/users/overview${params ? `?${params}` : ''}`;

      try {
        const { data } = (await getRequest(url)) as { data?: APIOrganizationOverview['data'] };
        setOrganizationOverview(
          data !== undefined ? generateOrganizationOverview(data) : undefined
        );
      } catch (error) {
        reportError(error);
      } finally {
        if (!skipSettingIsFetching) {
          setIsFetching(false);
        }
      }
    },
    [organizationId, getRequest, reportError]
  );

  const debouncedFetchOrganizationOverview = useDebounce(fetchOrganizationOverview, 200);

  useEffect(() => {
    if (!isFirstRender) return;
    setIsFetching(true);
    debouncedFetchOrganizationOverview();
    setIsFirstRender(false);

    return () => {
      setOrganizationOverview(undefined);
      setIsFetching(false);
      setQuery({});
    };
  }, [debouncedFetchOrganizationOverview, isFirstRender]);

  const updateOrganizationOverviewFilters = (newFilters: OrganizationOverviewFilters) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof OrganizationOverviewFilters] === undefined
        ? delete newFilters[key as keyof OrganizationOverviewFilters]
        : {}
    );
    if (isEqual(newFilters, query)) return;
    setQuery(newFilters);
    debouncedFetchOrganizationOverview(newFilters);
  };

  const refetchOrganizationOverview: RefetchOrganizationOverview = useCallback(
    (query, skipSettingIsFetching = false) => {
      debouncedFetchOrganizationOverview(query, skipSettingIsFetching);
    },
    [debouncedFetchOrganizationOverview]
  );

  return {
    isFetching,
    organizationOverview,
    refetchOrganizationOverview,
    updateOrganizationOverviewFilters
  };
};

export default useGetOrganizationOverview;
