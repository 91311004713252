import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'src/utils/useDebounce';
import { useIsFirstRender } from 'usehooks-ts';

import { generatePathway, Pathway } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

export interface PathwaysFilters {
  name?: string;
  page?: number;
  per_page?: number;
  'status[]'?: string[];
}

interface UseGetPathways {
  (filters: PathwaysFilters): {
    isFetching: boolean;
    pathways: Pathway[];
    pathwaysTotalPages: number;
    updatePathwaysFilters: (newFilters: Partial<PathwaysFilters>) => void;
  };
}

const useGetPathways: UseGetPathways = filters => {
  const { getRequest, reportError } = useApiRequest();
  const isFirstRender = useIsFirstRender();

  const [pathways, setPathways] = useState<Pathway[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [previousFilters, setPreviousFilters] = useState(filters);
  const [pathwaysTotalPages, setPathwaysTotalPages] = useState(1);

  const fetchPathways = useCallback(
    async (filters: PathwaysFilters) => {
      setIsFetching(true);

      const params = filtersToParams(filters);

      const url: RequestInfo = `${
        process.env.REACT_APP_API_V2_BASE_PATH
      }/pathways${params ? `?${params}` : ''}`;

      try {
        const { data, meta } = (await getRequest(url)) as {
          data?: APIPathwaysIndex['data'];
          meta?: APIPathwaysIndex['meta'];
        };
        setPathways(data !== undefined ? data.map(fields => generatePathway(fields)) : []);
        setPathwaysTotalPages(meta !== undefined ? meta.total_pages : 1);
      } catch (error) {
        reportError(error);
      } finally {
        setIsFetching(false);
      }
    },
    [getRequest, reportError]
  );

  const debouncedFetchPathways = useDebounce(fetchPathways, 200);

  useEffect(() => {
    if (!isFirstRender) return;
    setIsFetching(true);
    debouncedFetchPathways(filters);

    return () => {
      setPathways([]);
      setIsFetching(false);
      setPreviousFilters({});
    };
  }, [debouncedFetchPathways, isFirstRender, filters]);

  const updatePathwaysFilters = (newFilters: Partial<PathwaysFilters>) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof PathwaysFilters] === undefined
        ? delete newFilters[key as keyof PathwaysFilters]
        : {}
    );
    if (isEqual(newFilters, previousFilters)) return;

    setPreviousFilters({ ...newFilters });
    debouncedFetchPathways(newFilters);
  };

  return {
    isFetching,
    pathways,
    pathwaysTotalPages,
    updatePathwaysFilters
  };
};

export default useGetPathways;
