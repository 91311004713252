import { ToastState } from '@react-stately/toast';
import * as Sentry from '@sentry/react';
import { produce } from 'immer';
import { FormEventHandler, Key, useEffect, useRef, useState } from 'react';
import { AriaCheckboxGroupProps, AriaComboBoxProps, useFilter } from 'react-aria';
import { useOutletContext } from 'react-router-dom';
import { Item } from 'react-stately';
import ComboBox from 'src/components/FormFields/ComboBox';
import TextField from 'src/components/FormFields/TextField';
import PageTitle from 'src/components/PageTitle';
import { VioletToast } from 'src/components/ToastProvider';
import { STATES } from 'src/pages/constants';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import Button from '../../../../components/Buttons/Button';
import CheckboxGroup from '../../../../components/FormFields/CheckboxGroup';
import GroupCheckbox from '../../../../components/FormFields/CheckboxGroup/GroupCheckbox';
import useBreakpointRange from '../../../../hooks/useBreakpointRange';
import useOpenErrorModalDialog from '../../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../../../hooks/useUser';

import PhoneNumberField from './PhoneNumberField';
import * as S from './styles';

const allPronouns = ['She/Her/Hers', 'He/Him/His', 'They/Them/Theirs', 'Prefer not to say'];

const AccountInformation = () => {
  const { isInDesktopBreakpointRange } = useBreakpointRange();
  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const { bearerToken, setUser, user } = useUser();
  const toastState = useOutletContext();

  const formFieldRefs = {
    city: useRef<HTMLInputElement>(null),
    firstName: useRef<HTMLInputElement>(null),
    lastName: useRef<HTMLInputElement>(null),
    npi: useRef<HTMLInputElement>(null),
    phoneNumber: useRef<HTMLInputElement>(null)
  };
  const [pronounSets, setPronounSets] = useState<string[]>(user.user_info.pronouns);
  const [state, setState] = useState<Key | null | undefined>(user.state ?? undefined);

  const { contains } = useFilter({ sensitivity: 'base' });

  const functionToCallAfterUserUpdateRef = useRef<() => void>();

  useEffect(() => {
    if (functionToCallAfterUserUpdateRef.current) {
      const functionToCallAfterUserUpdate = functionToCallAfterUserUpdateRef.current;
      functionToCallAfterUserUpdateRef.current = undefined;
      functionToCallAfterUserUpdate();
    }
  }, [user]);

  const [formIsSubmitting, setFormIsSubmitting] = useState(false);

  const handleFormSubmit: FormEventHandler = async event => {
    event.preventDefault();

    setFormIsSubmitting(true);

    const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/profile`;

    const firstName = formFieldRefs.firstName.current!.value;
    const lastName = formFieldRefs.lastName.current!.value;
    const phoneNumber = formFieldRefs.phoneNumber.current!.value;
    const npi = isNonEmptyString(formFieldRefs.npi.current?.value)
      ? formFieldRefs.npi.current?.value
      : null;
    const city = formFieldRefs.city.current?.value ?? null;

    const options: RequestInit = {
      body: JSON.stringify({
        user: {
          city,
          first_name: firstName,
          last_name: lastName,
          state,
          user_info_attributes: {
            npi,
            phone_number: phoneNumber.replace(/[^0-9]/g, ''),
            pronouns: pronounSets
          }
        }
      }),
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PATCH'
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else if (response.status === 422) {
          const error = (await response.json()) as { ['user_info.npi']: string[] };
          if (error['user_info.npi'].includes('has already been taken')) {
            throw new Error('npi_error_taken');
          } else if (
            error['user_info.npi'].includes('is the wrong length (should be 10 characters)')
          ) {
            throw new Error('npi_error_length');
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { data } = (await response.json()) as APIUsersDashboardGeneralInfo;

      setUser(
        produce(user, draft => {
          draft.first_name = data.first_name;
          draft.last_name = data.last_name;
          draft.user_info.npi = data.user_info.npi;
          draft.user_info.phone_number = data.user_info.phone_number;
          draft.user_info.pronouns = data.user_info.pronouns;
          draft.city = data.city;
          draft.state = data.state;
        })
      );

      functionToCallAfterUserUpdateRef.current = () => {
        window.scrollTo(0, 0);
        (toastState as ToastState<VioletToast>).add(
          {
            description: 'Your data has been saved.',
            type: 'success'
          },
          { timeout: 8000 }
        );
      };
    } catch (error) {
      (toastState as ToastState<VioletToast>).add(
        {
          description: 'Something went wrong. Please check for errors.',
          type: 'error'
        },
        { timeout: 8000 }
      );

      if (error instanceof Error && error.message.includes('npi_error_')) {
        if (error.message.includes('npi_error_taken')) {
          formFieldRefs.npi.current!.setCustomValidity(
            'This NPI is already taken. Check it is typed correctly, or contact support.'
          );
          formFieldRefs.npi.current!.reportValidity();
        } else if (error.message.includes('npi_error_length')) {
          formFieldRefs.npi.current!.setCustomValidity('NPI should be 10 characters long.');
          formFieldRefs.npi.current!.reportValidity();
        }
      } else {
        Sentry.captureException(error);
        openErrorModalDialog();
      }
    }

    setFormIsSubmitting(false);
  };

  const handlePronounSetsChange: AriaCheckboxGroupProps['onChange'] = pronounSets => {
    setPronounSets(pronounSets);
  };

  const updateStateSelection: AriaComboBoxProps<object>['onSelectionChange'] = value =>
    setState(value);

  const clearNpiErrors = () => {
    formFieldRefs.npi.current!.setCustomValidity('');
  };

  return (
    <>
      <PageTitle
        title="Account information"
        titleVariant="h1"
      />
      <form onSubmit={handleFormSubmit}>
        <S.FormWrapper>
          <S.FormHeading>Personal information</S.FormHeading>
          <S.FormRow>
            <TextField
              ref={formFieldRefs.firstName}
              autoComplete="given-name"
              defaultValue={user.first_name!}
              isRequired
              label="First name"
              placeholder="First name"
              validationBehavior="native"
            />
            <TextField
              ref={formFieldRefs.lastName}
              autoComplete="family-name"
              defaultValue={user.last_name!}
              isRequired
              label="Last name"
              placeholder="Last name"
              validationBehavior="native"
            />
            <PhoneNumberField
              ref={formFieldRefs.phoneNumber}
              defaultValue={user.user_info.phone_number ?? ''}
            />
          </S.FormRow>
          {user.is_clinical && (
            <S.FormRow>
              <TextField
                ref={formFieldRefs.npi}
                data-cy="npi-field"
                defaultValue={user.user_info.npi ?? ''}
                errorMessage={formFieldRefs.npi.current?.validationMessage}
                isInvalid={formFieldRefs.npi.current?.validity.valid === false}
                label="NPI (National Provider ID)"
                onChange={clearNpiErrors}
                placeholder="NPI"
              />
              <TextField
                ref={formFieldRefs.city}
                autoComplete="address-level2"
                data-cy="city-field"
                defaultValue={user.city!}
                label="City"
              />
              <ComboBox
                data-cy="state-field"
                filter={contains}
                label="State"
                onSelectionChange={updateStateSelection}
                // Remove when this is resolved: https://github.com/adobe/react-spectrum/issues/5492
                //@ts-expect-error
                selectedKey={state}
              >
                {STATES.map(({ abbreviation, name }) => (
                  <Item
                    key={abbreviation}
                    textValue={`${abbreviation} (${name})`}
                  >
                    {abbreviation} ({name})
                  </Item>
                ))}
              </ComboBox>
            </S.FormRow>
          )}
          <S.Label>Pronouns*</S.Label>
          <CheckboxGroup
            allowOther
            aria-label="Pronouns*"
            data-cy="pronoun-sets-field"
            direction={isInDesktopBreakpointRange ? 'horizontal' : 'vertical'}
            groupLabelColor="gray-800"
            isRequired
            onChange={handlePronounSetsChange}
            otherLabel="Or enter your pronouns"
            size="regular"
            validationBehavior="native"
            value={pronounSets}
          >
            {allPronouns.map(pronouns => (
              <GroupCheckbox
                key={pronouns}
                name="selectedPronouns"
                value={pronouns}
              >
                {pronouns}
              </GroupCheckbox>
            ))}
          </CheckboxGroup>
        </S.FormWrapper>
        <S.Buttons>
          <Button
            data-cy="save-button"
            isLoading={formIsSubmitting}
            size="large"
            type="submit"
          >
            Save
          </Button>
        </S.Buttons>
      </form>
    </>
  );
};

export default AccountInformation;
