import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'src/utils/useDebounce';
import { useIsFirstRender } from 'usehooks-ts';

import { generateOrganizationDemographics, OrganizationDemographics } from '../utils';

import useApiRequest, { filtersToParams } from './useApiRequest';

type FetchOrganizationDemographics = (
  filters?: OrganizationDemographicsFilters,
  skipSettingIsFetching?: boolean
) => Promise<void>;

interface OrganizationDemographicsFilters {
  clinical?: boolean;
  completed_onboarding?: boolean;
  status?: string;
}

interface UseGetOrganizationDemographics {
  (
    organizationId: string,
    filters?: OrganizationDemographicsFilters
  ): {
    isFetching: boolean;
    organizationDemographics: OrganizationDemographics | undefined;
    updateOrganizationDemographicsFilters: (newFilters: OrganizationDemographicsFilters) => void;
  };
}

const useGetOrganizationDemographics: UseGetOrganizationDemographics = (
  organizationId,
  filters
) => {
  const isFirstRender = useIsFirstRender();
  const [organizationDemographics, setOrganizationDemographics] = useState<
    OrganizationDemographics | undefined
  >();
  const [isFetching, setIsFetching] = useState(false);
  const [query, setQuery] = useState<OrganizationDemographicsFilters>(filters ?? {});
  const { getRequest, reportError } = useApiRequest();

  const fetchOrganizationDemographics: FetchOrganizationDemographics = useCallback(
    async (filters, skipSettingIsFetching = false) => {
      if (!skipSettingIsFetching) {
        setIsFetching(true);
      }

      const params = filtersToParams(filters ?? {});

      const url: RequestInfo = `${
        process.env.REACT_APP_API_V2_BASE_PATH
      }/organizations/${organizationId}/users/demographics${params ? `?${params}` : ''}`;

      try {
        const { data } = (await getRequest(url)) as {
          data?: APIOrganizationDemographics['data'];
        };
        setOrganizationDemographics(
          data !== undefined ? generateOrganizationDemographics(data) : undefined
        );
      } catch (error) {
        reportError(error);
      } finally {
        if (!skipSettingIsFetching) {
          setIsFetching(false);
        }
      }
    },
    [getRequest, reportError, organizationId]
  );

  const debouncedFetchOrganizationDemographics = useDebounce(fetchOrganizationDemographics, 200);

  useEffect(() => {
    /*
      React StrictMode causes this initial fetch
      to happen twice even with no dependencies, resulting
      in a double API call w/ no filter changes.
      This is a workaround for now that will run the
      initial fetch only once after the component calling
      this hook has been mounted.
    */
    if (!isFirstRender) return;
    setIsFetching(true);
    debouncedFetchOrganizationDemographics(filters);

    return () => {
      setOrganizationDemographics(undefined);
      setIsFetching(false);
      setQuery({});
    };
  }, [debouncedFetchOrganizationDemographics, isFirstRender, filters]);

  const updateOrganizationDemographicsFilters = (
    newFilters: Partial<OrganizationDemographicsFilters> = {}
  ) => {
    Object.keys(newFilters).forEach(key =>
      newFilters[key as keyof OrganizationDemographicsFilters] === undefined
        ? delete newFilters[key as keyof OrganizationDemographicsFilters]
        : {}
    );
    if (isEqual(newFilters, query)) return;
    setQuery(newFilters);
    debouncedFetchOrganizationDemographics(newFilters);
  };

  return {
    isFetching,
    organizationDemographics,
    updateOrganizationDemographicsFilters
  };
};

export default useGetOrganizationDemographics;
