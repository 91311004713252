import clsx from 'clsx';
import { useRef } from 'react';
import { AriaSwitchProps, useFocusRing, useSwitch, VisuallyHidden } from 'react-aria';
import { useToggleState } from 'react-stately';

import * as S from './styles';

interface Props extends AriaSwitchProps {
  className?: string;
  offLabel?: string;
  onLabel?: string;
  variant?: 'primary' | 'secondary';
}

const Switch = ({
  className,
  offLabel,
  onLabel,
  variant = 'primary',
  ...ariaSwitchProps
}: Props) => {
  const state = useToggleState(ariaSwitchProps);
  const ref = useRef<HTMLInputElement>(null);
  const { inputProps, isSelected } = useSwitch(ariaSwitchProps, state, ref);
  const { focusProps, isFocusVisible } = useFocusRing();

  const { children } = ariaSwitchProps;

  return (
    <S.SwitchContainer $hasLabel={Boolean(children)}>
      <S.Switch className={clsx(className, { 'is-focused': isFocusVisible })}>
        <VisuallyHidden>
          <input
            {...inputProps}
            {...focusProps}
            ref={ref}
          />
        </VisuallyHidden>
        <S.SwitchButton
          $variant={variant}
          className={clsx({ 'is-selected': !isSelected })}
        >
          {offLabel}
        </S.SwitchButton>
        <S.SwitchButton
          $variant={variant}
          className={clsx({ 'is-selected': isSelected })}
        >
          {onLabel}
        </S.SwitchButton>
      </S.Switch>
      <S.Label>{children}</S.Label>
    </S.SwitchContainer>
  );
};

export default Switch;
