import * as S from './styles';

interface Props {
  children: JSX.Element | number | string;
}

const TableCenterCell = ({ children, ...args }: Props) => (
  <S.TableCenterCell {...args}>{children}</S.TableCenterCell>
);

export default TableCenterCell;
