import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'src/utils/useDebounce';

import { EducationalResource, generateEducationalResource } from '../utils';

import useApiRequest from './useApiRequest';

type FetchCourse = (skipSettingIsFetching?: boolean, courseId?: string) => void;

type RefetchFetchCourse = FetchCourse;

interface UseGetCourse {
  (id: string | undefined): {
    changeCourseId: (id: string) => void;
    course: EducationalResource | undefined;
    isFetching: boolean;
    refetchCourse: RefetchFetchCourse;
  };
}

const useGetCourse: UseGetCourse = id => {
  const { getRequest, reportError } = useApiRequest();

  const [course, setCourse] = useState<EducationalResource | undefined>();
  const [isFetching, setIsFetching] = useState(false);
  const [courseId, setCourseId] = useState<string | undefined>(id);

  const fetchCourse: FetchCourse = useCallback(
    async (skipSettingIsFetching = false, courseId?: string) => {
      if (courseId === undefined || courseId === '' || courseId === 'undefined') return;

      if (!skipSettingIsFetching) {
        setIsFetching(true);
      }

      const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/courses/${courseId}`;

      try {
        const { data } = (await getRequest(url)) as { data?: APICoursesCourseId['data'] };
        setCourse(data !== undefined ? generateEducationalResource(data) : undefined);
      } catch (error) {
        reportError(error);
      } finally {
        if (!skipSettingIsFetching) {
          setIsFetching(false);
        }
      }
    },
    [getRequest, reportError]
  );

  const debouncedFetchCourse = useDebounce(fetchCourse, 200);

  useEffect(() => {
    debouncedFetchCourse(false, courseId);

    return () => {
      setCourse(undefined);
      setIsFetching(false);
    };
  }, [courseId, debouncedFetchCourse, id]);

  const refetchCourse: RefetchFetchCourse = useCallback(
    (skipSettingIsFetching = false) => {
      debouncedFetchCourse(skipSettingIsFetching, courseId);
    },
    [debouncedFetchCourse, courseId]
  );

  const changeCourseId = (id: string) => {
    setCourseId(id);
  };

  return { changeCourseId, course, isFetching, refetchCourse };
};

export default useGetCourse;
