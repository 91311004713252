import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { useAsyncList } from 'react-stately';
import useUser from 'src/hooks/useUser';
import useGetCollections from 'src/pages/Dashboard/hooks/useGetCollections';
import useGetPathways from 'src/pages/Dashboard/hooks/useGetPathways';
import {
  Collection,
  EducationalResource,
  generateCollection,
  generateEducationalResource,
  Pathway
} from 'src/pages/Dashboard/utils';
import isNonEmptyString from 'src/utils/isNonEmptyString';
import { useIsFirstRender, useUpdateEffect } from 'usehooks-ts';

import CollectionCard from '../CollectionCard';
import CourseCard from '../CourseCard';
import PathwayCard from '../PathwayCard';

import * as S from './styles';

type CollectionWithResourceType = Collection & { resourceType: string };
type EducationalResourceWithResourceType = EducationalResource & { resourceType: string };
type PathwayWithResourceType = Pathway & { resourceType: string };

type ResourceList = (
  | CollectionWithResourceType
  | EducationalResourceWithResourceType
  | PathwayWithResourceType
)[];

const TAB_TEXT = {
  assignments:
    'These courses have been assigned to you by your sponsoring organization. For more details, hover over the suitcase icon in the upper right section of a course, collection, or pathway card.',
  in_progress:
    'Pick up where you left off and complete the collections or pathways you’ve started.',
  new: "Explore the latest additions to our course catalog. Remember to check back often, as we're constantly adding new collections.",
  trending:
    "Here's a glimpse into what's currently trending among our partnering providers and health care staff."
};

type TabOptions = 'assignments' | 'in_progress' | 'new' | 'trending';

const EducationCarousel = () => {
  const { bearerToken, user } = useUser();
  const fetchOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${bearerToken}`
    }
  };

  const [activeTab, setActiveTab] = useState<TabOptions>(
    user.active_assignments_count === 0 ||
      user.active_assignments_count === user.completed_active_assignments_count
      ? 'new'
      : 'assignments'
  );
  const [isLoading, setIsLoading] = useState(true);
  const [hasInProgressResources, setHasInProgressResources] = useState(false);
  const [inProgressResources, setInProgressResources] = useState<ResourceList>([]);
  const isFirstRender = useIsFirstRender();
  const { collections: inProgressCollections, isFetching: isFetchingCollections } =
    useGetCollections({
      page: 1,
      per_page: 3,
      status: ['in_progress']
    });
  const { isFetching: isFetchingPathways, pathways: inProgressPathways } = useGetPathways({
    page: 1,
    per_page: 3,
    'status[]': ['in_progress']
  });

  useUpdateEffect(() => {
    if (isFetchingPathways || isFetchingCollections) return;
    const combinedInProgressResources = [
      ...inProgressPathways.map(pathway => ({ ...pathway, resourceType: 'Pathway' })),
      ...inProgressCollections.map(collection => ({
        ...collection,
        resourceType: 'CourseCollection'
      }))
    ];
    setInProgressResources(combinedInProgressResources.slice(0, 3));
    setHasInProgressResources(combinedInProgressResources.length > 0);
  }, [isFetchingCollections, isFetchingPathways, inProgressPathways]);

  const resourceList = useAsyncList({
    async load({ filterText, signal }) {
      setIsLoading(true);
      let type = filterText;
      if (
        (user.active_assignments_count === 0 ||
          user.active_assignments_count === user.completed_active_assignments_count) &&
        (type === '' || type === undefined)
      ) {
        type = 'new';
      }
      const isAssignedTab = type === 'assignments' || type === '' || type === undefined;
      const isInProgressTab = type === 'in_progress';
      let resources: ResourceList = [];

      try {
        let url = '';
        if (isAssignedTab) {
          url = `${process.env.REACT_APP_API_V2_BASE_PATH}/course_collections?per_page=6&assigned=true&completed=false`;
        } else if (type === 'new') {
          url = `${process.env.REACT_APP_API_V2_BASE_PATH}/course_collections?per_page=3&is_new=true`;
        } else if (type === 'trending') {
          url = `${process.env.REACT_APP_API_V2_BASE_PATH}/course_collections?per_page=3&is_popular=true`;
        } else {
          // in-progress
          url = '';
        }
        if (isNonEmptyString(url)) {
          const res = await fetch(url, { ...fetchOptions, signal });
          const json = (await res.json()) as APICourseCollections;

          resources = json.data.map(fields => generateCollection(fields, user)) as ResourceList;

          resources = resources.map(resource => ({
            ...resource,
            resourceType: 'CourseCollection'
          }));

          if (isAssignedTab) {
            const courseUrl = `${process.env.REACT_APP_API_V2_BASE_PATH}/courses?per_page=6&assigned=true&completed=false`;
            const courseRes = await fetch(courseUrl, { ...fetchOptions, signal });
            const courseJson = (await courseRes.json()) as APICourses;

            let courses = courseJson.data.map(fields => generateEducationalResource(fields));
            courses = courses.map(course => ({ ...course, resourceType: 'Course' }));

            resources = [...resources, ...courses] as ResourceList;
          }
        } else if (isInProgressTab) {
          if (hasInProgressResources) {
            resources = inProgressResources;
          } else {
            resources = [];
          }
        }
      } catch (error) {
        console.error(error);
      }

      setIsLoading(false);

      return {
        items: resources
      };
    }
  });

  const setTab = (tab: TabOptions) => {
    resourceList.setFilterText(tab);
    setActiveTab(tab);
  };

  const mockedRefetchEducationalResources = async (
    skipSettingIsFetching?: boolean | undefined
  ): Promise<void> =>
    new Promise(resolve => {
      const timeout = skipSettingIsFetching === true ? 0 : 500;
      setTimeout(() => {
        resourceList.setFilterText(activeTab);
        resolve();
      }, timeout);
    });

  return (
    <S.Carousel data-cy="education-carousel">
      <S.Navigation>
        {user.active_assignments_count > 0 &&
          user.active_assignments_count !== user.completed_active_assignments_count && (
            <S.NavButton
              className={activeTab === 'assignments' ? 'active' : ''}
              data-cy="assignments-tab"
              onPress={() => setTab('assignments')}
            >
              <S.Tab className={activeTab === 'assignments' ? 'active' : ''}>Assignments</S.Tab>
            </S.NavButton>
          )}
        <S.NavButton
          className={activeTab === 'new' ? 'active' : ''}
          data-cy="new-tab"
          onPress={() => setTab('new')}
        >
          <S.Tab className={activeTab === 'new' ? 'active' : ''}>New</S.Tab>
        </S.NavButton>
        <S.NavButton
          className={activeTab === 'trending' ? 'active' : ''}
          data-cy="trending-tab"
          onPress={() => setTab('trending')}
        >
          <S.Tab className={activeTab === 'trending' ? 'active' : ''}>Trending</S.Tab>
        </S.NavButton>
        {hasInProgressResources && (
          <S.NavButton
            className={activeTab === 'in_progress' ? 'active' : ''}
            data-cy="in-progress-tab"
            onPress={() => setTab('in_progress')}
          >
            <S.Tab className={activeTab === 'in_progress' ? 'active' : ''}>In Progress</S.Tab>
          </S.NavButton>
        )}
      </S.Navigation>
      <AnimatePresence mode="wait">
        {isLoading ? (
          <S.CarouselSpinnerContainer
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            transition={{ delay: isFirstRender ? 2 : 0, duration: 0.25 }}
          >
            <S.CarouselSpinner withWrapper />
          </S.CarouselSpinnerContainer>
        ) : (
          <S.CarouselContent
            key={`content-block-${activeTab}`}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            transition={{ delay: isFirstRender ? 2 : 0, duration: 0.75 }}
          >
            <S.TabDescriptionText key={`tab-description-${activeTab}`}>
              {TAB_TEXT[activeTab]}
            </S.TabDescriptionText>
            <S.ResourceList key={`resource-list-${activeTab}`}>
              {(resourceList.items as ResourceList).map(resource =>
                resource.resourceType === 'CourseCollection' ? (
                  <CollectionCard
                    key={(resource as Collection).id}
                    collection={resource as Collection}
                  />
                ) : resource.resourceType === 'Course' ? (
                  <CourseCard
                    key={(resource as EducationalResource).id}
                    course={resource as EducationalResource}
                    refetchEducationalResources={mockedRefetchEducationalResources}
                  />
                ) : (
                  <PathwayCard
                    key={(resource as Pathway).id}
                    pathway={resource as Pathway}
                  />
                )
              )}
            </S.ResourceList>
          </S.CarouselContent>
        )}
      </AnimatePresence>
    </S.Carousel>
  );
};

export default EducationCarousel;
