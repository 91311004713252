import { ToastState } from '@react-stately/toast';
import { Sortable } from '@react-types/shared';
import capitalize from 'lodash/capitalize';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { Key, useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { Item, SortDescriptor, SortDirection } from 'react-stately';
import ButtonWithMenu from 'src/components/Buttons/ButtonWithMenu';
import PageTitle from 'src/components/PageTitle';
import Spinner from 'src/components/Spinner';
import { VioletToast } from 'src/components/ToastProvider';
import useUser from 'src/hooks/useUser';
import useGetNetworkLocations from 'src/pages/Dashboard/hooks/useGetNetworkLocations';
import useGetNetworkOverview from 'src/pages/Dashboard/hooks/useGetNetworkOverview';

import { NETWORK_INCLUSIVITY_URL_W_PARAMS } from '../../Wrapper';
import { NetworkOverviewSection } from '../NetworkOverview';
import { ServiceAreasTable } from '../Tables/ServiceAreasTable';

import ServiceAreaFilters from './ServiceAreaFilters';
import * as S from './styles';
import { mapLocationsFromKeysToAPIObject, useExportServiceAreas } from './useExportServiceAreas';

const ServiceAreas = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { bearerToken, user } = useUser();
  const fetchOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${bearerToken}`
    }
  };
  const toastState = useOutletContext<ToastState<VioletToast>>();
  const [isExporting, setIsExporting] = useState(false);
  const hasServiceAreasEnabled = useFeatureFlagEnabled('network_granular_geography');

  useEffect(() => {
    if (hasServiceAreasEnabled === false) {
      navigate(NETWORK_INCLUSIVITY_URL_W_PARAMS, { replace: true });
    }
  }, [hasServiceAreasEnabled, navigate]);

  /**** TABLE STATE ****/
  const [view, setView] = useState<string>(searchParams.get('view') ?? 'county');
  const [currentPage, setCurrentPage] = useState<number>(
    searchParams.get('page') !== null ? Number(searchParams.get('page')) : 1
  );
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column:
      searchParams.get('order_by[column]') !== null
        ? (searchParams.get('order_by[column]') as 'provider_count' | 'state')
        : 'provider_count',
    direction:
      searchParams.get('order_by[dir]') === null
        ? 'descending'
        : ({ asc: 'ascending', desc: 'descending' }[
            searchParams.get('order_by[dir]')!
          ] as SortDirection)
  });

  /**** TABLE HANDLERS ****/
  const handleTableSortChange: Sortable['onSortChange'] = ({ column, direction }) => {
    setSearchParams(params => {
      params.set('order_by[column]', column.toString());
      params.set('order_by[dir]', direction === 'ascending' ? 'asc' : 'desc');
      params.set('page', '1');
      return params;
    });
  };

  const handleSetPage = (page: number) => {
    setSearchParams(params => {
      params.set('page', page.toString());
      return params;
    });
  };

  const handleViewChange = (view: Key) => {
    setSearchParams(params => {
      params.set('page', '1');
      params.set('view', view.toString());
      return params;
    });
  };

  /**** HELPER FUNCTIONS ****/
  const getCommonFilters = useCallback(
    () => ({
      'benchmark_communities[]': searchParams.get('benchmark_communities[]')?.split(',') ?? [],
      locations: mapLocationsFromKeysToAPIObject(searchParams.get('locations[]')?.split(',') ?? []),
      org_is_on_violet: searchParams.get('org_is_on_violet') === 'true' ? true : undefined,
      'organizations[]': searchParams.get('organizations[]')?.split(',') ?? [],
      'specialties[]': searchParams.get('specialties[]')?.split(',') ?? [],
      verified_inclusive: searchParams.get('verified_inclusive') === 'true' ? true : undefined
    }),
    [searchParams]
  );

  const { handleExportCSV } = useExportServiceAreas({
    fetchOptions,
    searchParams,
    setIsExporting,
    toastState,
    user
  });

  /**** INITIAL DATA FETCH ****/
  const { isFetching, networkLocations, networkLocationsTotalPages, updateNetworkLocationFilters } =
    useGetNetworkLocations({
      ...getCommonFilters(),
      order_by: {
        column: (searchParams.get('order_by[column]') !== null
          ? searchParams.get('order_by[column]')
          : 'provider_count') as 'provider_count' | 'state',
        dir: searchParams.get('order_by[dir]') === 'asc' ? 'asc' : 'desc'
      },
      page: parseInt(searchParams.get('page') ?? '1', 10),
      view:
        (searchParams.get('view') as 'city' | 'county' | 'state' | 'zip' | undefined) ?? 'county'
    });

  const {
    isFetching: isFetchingOverview,
    networkOverview,
    updateNetworkOverviewFilters
  } = useGetNetworkOverview({ ...getCommonFilters() });

  /* refetch data when searchParams changes */
  useEffect(() => {
    updateNetworkLocationFilters({
      ...getCommonFilters(),
      order_by: {
        column: (searchParams.get('order_by[column]') !== null
          ? searchParams.get('order_by[column]')
          : 'provider_count') as 'provider_count' | 'state',
        dir: searchParams.get('order_by[dir]') === 'asc' ? 'asc' : 'desc'
      },
      page: parseInt(searchParams.get('page') ?? '1', 10),
      view:
        (searchParams.get('view') as 'city' | 'county' | 'state' | 'zip' | undefined) ?? 'county'
    });

    updateNetworkOverviewFilters({
      ...getCommonFilters()
    });
  }, [searchParams, updateNetworkLocationFilters, updateNetworkOverviewFilters, getCommonFilters]);

  /* sets the current page and sort descriptor when searchParams changes */
  useEffect(() => {
    setCurrentPage(Number(searchParams.get('page') ?? '1'));
    setSortDescriptor({
      column: (searchParams.get('order_by[column]') !== null
        ? searchParams.get('order_by[column]')
        : 'provider_count') as 'provider_count' | 'state',
      direction:
        searchParams.get('order_by[dir]') === null
          ? 'descending'
          : ({ asc: 'ascending', desc: 'descending' }[
              searchParams.get('order_by[dir]')!
            ] as SortDirection)
    });
    setView(searchParams.get('view') ?? 'county');
  }, [searchParams]);

  return (
    <>
      <PageTitle
        description="Explore provider inclusivity by geographic region to meet state reporting needs and optimize network performance. Toggle between city, county, and zip code views, and apply filters to better understand your network. Download detailed reports to share insights and drive data-informed decisions for your team."
        title="Service areas"
        titleVariant="h1"
      />
      <ServiceAreaFilters
        exportButton={
          <S.ExportButton
            data-cy="export-csv-button"
            isDisabled={isExporting}
            isLoading={isExporting}
            onPress={handleExportCSV}
            variant="primary"
          >
            Export
          </S.ExportButton>
        }
        sortDescriptor={sortDescriptor}
      />

      {isFetchingOverview ? (
        <Spinner withWrapper />
      ) : (
        <NetworkOverviewSection
          networkOverview={networkOverview}
          showDiversity
          showVIOMetrics
        />
      )}
      <S.TableWrapper>
        <S.TableHeaderWrapper>
          <S.TableTextWrapper>
            <S.TableTitle>Service areas</S.TableTitle>
            <S.TableDescription>
              This table displays the density of inclusive providers by geographic service area.
            </S.TableDescription>
          </S.TableTextWrapper>
          <S.TableActionsWrapper>
            <ButtonWithMenu
              customWidth={220}
              data-cy="service-area-view-button"
              label={`View: ${capitalize(view)}`}
              onAction={handleViewChange}
              placement="bottom end"
              variant="plain"
            >
              <Item key="state">
                <S.OptionWrapper>
                  State{' '}
                  {view === 'state' ? (
                    <S.CheckedIcon
                      aria-label="checked"
                      data-cy="is-selected-icon"
                      role="img"
                    />
                  ) : null}
                </S.OptionWrapper>
              </Item>
              <Item key="city">
                <S.OptionWrapper>
                  City{' '}
                  {view === 'city' ? (
                    <S.CheckedIcon
                      aria-label="checked"
                      data-cy="is-selected-icon"
                      role="img"
                    />
                  ) : null}
                </S.OptionWrapper>
              </Item>
              <Item key="county">
                <S.OptionWrapper>
                  County{' '}
                  {view === 'county' ? (
                    <S.CheckedIcon
                      aria-label="checked"
                      data-cy="is-selected-icon"
                      role="img"
                    />
                  ) : null}
                </S.OptionWrapper>
              </Item>
              <Item key="zip">
                <S.OptionWrapper>
                  Zip{' '}
                  {view === 'zip' ? (
                    <S.CheckedIcon
                      aria-label="checked"
                      data-cy="is-selected-icon"
                      role="img"
                    />
                  ) : null}
                </S.OptionWrapper>
              </Item>
            </ButtonWithMenu>
          </S.TableActionsWrapper>
        </S.TableHeaderWrapper>
        {isFetching ? (
          <Spinner withWrapper />
        ) : networkLocations && networkLocations.length > 0 ? (
          <ServiceAreasTable
            currentPageLocations={currentPage}
            handleLocationsTableSortChange={handleTableSortChange}
            handleSetPageLocations={handleSetPage}
            networkLocations={networkLocations}
            networkLocationsTotalPages={networkLocationsTotalPages}
            sortDescriptorLocations={sortDescriptor}
          />
        ) : (
          <S.EmptyState data-cy="network-service-area-empty-state">
            No service areas found.
          </S.EmptyState>
        )}
      </S.TableWrapper>
    </>
  );
};

export default ServiceAreas;
