import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const TableActionsWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  gap: 0.5rem;
`;

export const TableDescription = styled.div`
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-800);
`;

export const TableHeaderWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

export const TableTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-grow: 1;
`;

export const TableTitleH1 = styled.h1`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--gray-800);
  margin: 0;
`;

export const TableTitleH2 = styled.h2`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--gray-800);
  margin: 0;
`;

export const TableWrapper = styled.div`
  border-radius: 1rem;
  border: 1px solid var(--gray-200);
  background: #fff;
  padding: 1.5rem;
  margin-bottom: 1rem;
`;
