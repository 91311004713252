import styled from 'styled-components';

import { FOCUS_STYLES, TEXT_STYLES } from '../../../constants';

export const Label = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  color: var(--gray-800);
`;

export const Switch = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 3rem;
  border: 1px solid var(--gray-200);
  background: var(--white-light);
  cursor: pointer;

  &.is-focused {
    ${FOCUS_STYLES}
    border-radius: 3rem;
  }
`;

interface SwitchButtonProps {
  $variant: 'primary' | 'secondary';
}

export const SwitchButton = styled.div<SwitchButtonProps>`
  ${TEXT_STYLES.buttonTextLargeScreen}
  height: 2.3125rem;
  width: 8.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6.25rem;

  ${({ $variant }) => {
    if ($variant === 'primary') {
      return `
        color: var(--gray-400);

        &.is-selected {
          color: var(--white-light);
          background-color: var(--purple-400);
          border: 1px solid var(--purple-400);
          margin: -1px;
        }
      `;
    } else {
      return `
        color: var(--gray-400);

        &.is-selected {
          color: var(--white-light);
          background-color: var(--gray-500);
          border: 1px solid var(--gray-500);
          margin: -1px;
        }
      `;
    }
  }}
`;

interface SwitchContainerProps {
  $hasLabel: boolean;
}

export const SwitchContainer = styled.label<SwitchContainerProps>`
  display: inline-flex;
  align-items: center;

  ${({ $hasLabel }) =>
    $hasLabel &&
    `
    ${Label} {
  gap: 0.5rem;
    }
  `}
`;
