import { ReactComponent as QuestionMarkIcon } from '@material-design-icons/svg/round/help.svg';
import Button from 'src/components/Buttons/Button';
import ExpandableEditableTable from 'src/components/ExpandableEditableTable';
import UnstyledButton from 'src/components/UnstyledButton';
import { DESKTOP_BREAKPOINT_RANGE, MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const AddEducationButton = styled(Button)`
  margin-right: 1rem;

  ${DESKTOP_BREAKPOINT_RANGE} {
    margin-top: 1rem;
    margin-bottom: var(--space-7);
    margin-left: var(--space-2);
  }
`;

export const CenterText = styled.div`
  text-align: center;
`;

export const ClinicalActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
`;

export const EditableEducationTable = styled(ExpandableEditableTable)`
  border: none;
  padding: 0;

  ${MOBILE_BREAKPOINT_RANGE} {
    margin-bottom: 1rem;

    div[role='row'] {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
        'expand-collapse expand-collapse  delete'
        'education-name education-name education-name'
        'collapse-content collapse-content collapse-content';
    }
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    tr:not(.empty-row) {
      grid-template-columns:
        0 minmax(0, 4fr) minmax(0, 4fr) minmax(0, 2fr) minmax(0, 2fr) minmax(0, 3.5fr)
        minmax(0, 2fr) minmax(0, 1fr) 0fr;

      td:first-child,
      th:first-child,
      td:last-child,
      th:last-child {
        padding: 0;
      }
    }
  }

  tr.empty-row {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

export const HelpIcon = styled(QuestionMarkIcon)`
  margin-left: 0.25rem;
  fill: currentcolor;
  height: 1rem;
  width: 1rem;
`;

export const PopoverButton = styled(UnstyledButton)`
  display: inline-flex;
  align-items: center;

  &.is-focused {
    outline-color: var(--brand-bright);
    outline-offset: 0.25rem;
  }
`;

export const PopoverContent = styled.div`
  ${TEXT_STYLES.tooltipText}
  color: var(--gray-500);
  max-width: 18.75rem;
`;

export const Spacer = styled.div`
  height: var(--space-8);
`;

export const SpinnerWrapper = styled.div`
  display: grid;
  place-content: center;
  height: 100dvh;
`;
