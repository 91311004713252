import { MOBILE_BREAKPOINT_RANGE } from 'src/constants';
import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-spacing: 0 0.5rem;
`;

export const TableWrapper = styled.div`
  border: 1px solid var(--gray-200);
  background-color: #fff;
  border-radius: 1rem;
  padding: 0 0.625rem 0.125rem;
  overflow-x: auto;
  width: 100%;

  ${MOBILE_BREAKPOINT_RANGE} {
    overflow-x: auto;
    width: 100%;
  }
`;
