import { ReactComponent as greenCheckIcon } from '@material-design-icons/svg/round/check.svg';
import Select from 'src/components/FormFields/Select';
import DataReportRow from 'src/components/Reporting/DataReportRow';
import Filters from 'src/components/Table/Filters';
import UnstyledButton from 'src/components/UnstyledButton';
import { FOCUS_STYLES, MOBILE_BREAKPOINT_RANGE } from 'src/constants';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../constants';

import VioRequirements from './components/VioRequirements';

export const CourseLink = styled(UnstyledButton)`
  ${TEXT_STYLES.tagLarge}
  appearance: none;
  color: var(--blue-500);
  text-decoration: none;
  border: 0;
  text-align: left;
  background-color: transparent;
  cursor: pointer;

  margin-left: 0.75rem;

  &.is-pressed {
    color: var(--blue-600);
    ${FOCUS_STYLES}
  }

  &.is-hovered {
    text-decoration: underline;
  }

  &.is-focused {
    ${FOCUS_STYLES}
  }
`;

export const DateSelect = styled(Select)`
  ${DESKTOP_BREAKPOINT_RANGE} {
    max-width: 13.68rem;
  }
`;

export const EmailReminderCheck = styled(greenCheckIcon)`
  fill: var(--success-600);
  margin-right: 0.25rem;
`;

export const EmailReminderSentText = styled.div`
  ${TEXT_STYLES.bodyCopySmall}
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
`;

export const EngagementList = styled.ol`
  ${TEXT_STYLES.tagLarge}
  padding-left: 1rem;
  display: grid;
  gap: 0.25rem;
  margin: 0;
`;

export const EngagementTitle = styled.h3`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--purple-400);
  margin-bottom: 0.75rem;
`;

export const FilterWrapper = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-column: span 2;

  ${MOBILE_BREAKPOINT_RANGE} {
    grid-template-columns: 1fr;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

// this is a hack to allow us to render the report offscreen and then download it
export const HiddenOffscreenReportRender = styled.div`
  left: -2000px;
  overflow: visible;
  position: absolute;
  top: -2000px;
`;

export const NetworkManagerButton = styled(UnstyledButton)`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 1rem;
  border: 1px solid var(--gray-200);
  color: var(--gray-800);
  ${TEXT_STYLES.tagLarge}

  > svg {
    fill: var(--gray-400);
  }

  &:hover,
  &.is-hovered {
    border-color: var(--gray-400);

    > svg {
      fill: var(--gray-500);
    }
  }

  &:focus,
  &.is-focused {
    outline-color: var(--bright-purple-400);
  }
`;

export const NetworkManagerButtonContent = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    fill: var(--purple-400);
  }
`;

export const Requirements = styled(VioRequirements)`
  margin: 0.5rem 0;
`;

export const TeamMemberSelect = styled(Select)`
  ${DESKTOP_BREAKPOINT_RANGE} {
    max-width: initial;
  }
`;

export const TopLearnerListItem = styled.div`
  margin-left: 0.75rem;
`;

export const UserFilters = styled(Filters)`
  background-color: #fff;
`;

export const VioCard = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 1rem;
  border: 1px solid var(--gray-200);
  gap: 0.5rem;
  min-width: 13rem;

  &.wide {
    min-width: 27rem;

    @media all and (width <= 1118px) {
      button {
        width: auto;
      }
    }
  }
`;

export const VioCardBottomGrouping = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: auto;
  width: 100%;
`;

export const VioCardTitle = styled.h3`
  color: var(--gray-800);
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  margin: 0;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;

  &.justify-between {
    justify-content: space-between;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const VioRow = styled(DataReportRow)`
  ${DESKTOP_BREAKPOINT_RANGE} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const VioTotal = styled.div`
  color: var(--gray-800);
  text-align: center;
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 3.9rem */
  letter-spacing: 0.0325rem;
`;
