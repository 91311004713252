import Table from 'src/components/Table';
import styled from 'styled-components';

export const EducationTable = styled(Table)`
  border-color: var(--gray-200);
`;

export const TableHeaderCell = styled.div<TableHeaderCellProps>`
  text-transform: uppercase;

  ${({ $align = 'start' }) => {
    if ($align === 'end') {
      return `
      margin-left: auto;
      padding-right: 1.25rem;
    `;
    } else if ($align === 'center') {
      return `
      margin: 0 auto;
    `;
    }
  }}
`;

interface TableHeaderCellProps {
  $align?: 'center' | 'end' | 'start';
}
