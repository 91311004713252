import { Education } from 'src/pages/Dashboard/utils';

import CommunityCheck from '../CommunityCheck';

import * as S from './styles';

interface Props {
  educations: Education[];
}

const MobileCards = ({ educations }: Props) => (
  <S.MobileCards>
    {educations.length > 0 ? (
      educations.map(education => (
        <S.Card
          key={education.id}
          data-cy="completed-course"
        >
          <S.CardRow>
            <S.Label>Course Name</S.Label>
            <S.ValueLink to={`/dashboard/education/all-education/${education.courseId}`}>
              {education.courseName}
            </S.ValueLink>
          </S.CardRow>
          <S.CardRow>
            <S.Label>Organization</S.Label>
            <S.Value>{education.organization}</S.Value>
          </S.CardRow>
          <S.CardRow>
            <S.Label>Year</S.Label>
            <S.Value>{education.year}</S.Value>
          </S.CardRow>
          <S.CardRow>
            <S.Label>Credits</S.Label>
            <S.Value>{education.credits}</S.Value>
          </S.CardRow>
          <S.Label className="section-label">Communities of Focus</S.Label>
          <S.CardRowTable>
            <S.CardRowTableColumn>
              <S.Label className="label">BIPOC</S.Label>
              <S.Value>
                <CommunityCheck
                  communities={education.communities}
                  community="bipoc"
                />
              </S.Value>
            </S.CardRowTableColumn>
            <S.CardRowTableColumn>
              <S.Label className="label">LGBQ</S.Label>
              <S.Value>
                <CommunityCheck
                  communities={education.communities}
                  community="lgbq"
                />
              </S.Value>
            </S.CardRowTableColumn>
            <S.CardRowTableColumn>
              <S.Label className="label">TGNC</S.Label>
              <S.Value>
                <CommunityCheck
                  communities={education.communities}
                  community="tgnc"
                />
              </S.Value>
            </S.CardRowTableColumn>
          </S.CardRowTable>
        </S.Card>
      ))
    ) : (
      <S.Card>
        <S.BlankText>No courses have been completed.</S.BlankText>
      </S.Card>
    )}
  </S.MobileCards>
);

export default MobileCards;
