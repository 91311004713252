import { ToastState } from '@react-stately/toast';
import { FormEventHandler, useMemo, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Button from 'src/components/Buttons/Button';
import ButtonGroup from 'src/components/Buttons/ButtonGroup';
import { VioletToast } from 'src/components/ToastProvider';
import UserEducationForm from 'src/pages/components/UserEducationForm';
import {
  EducationsContext,
  revalidateEducationRows
} from 'src/pages/components/UserEducationForm/useEducationsContext';

import useGetEducations from '../../hooks/useGetEducations';
import { Education } from '../../utils';

const EducationPage = () => {
  const { createEducation, deleteEducation, patchEducation } = useGetEducations({}, true);

  const toastState = useOutletContext();

  const formRef = useRef<HTMLFormElement>(null);
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [invalidRowIndices, setInvalidRowIndices] = useState<number[]>([]);
  const [originalEducations, setOriginalEducations] = useState<Education[]>([]);
  const [draftEducations, setDraftEducations] = useState<Education[]>([]);

  const handleFormSubmit: FormEventHandler = event => {
    event.preventDefault();
    const invalidRowIndices = revalidateEducationRows(draftEducations);
    setInvalidRowIndices(invalidRowIndices);

    setHasAttemptedSubmit(true);

    if (invalidRowIndices.length > 0) {
      /* Don't attempt submission if any rows are invalid */
      if (!formRef.current) {
        window.scrollTo(0, 0);
      } else {
        formRef.current.reportValidity();
      }
      return;
    }

    if (formRef.current?.checkValidity() === false) {
      formRef.current.reportValidity();
      return;
    }

    /* Thou may proceed to handle submission */
    setFormIsSubmitting(true);

    let encounteredError = 0;

    /* Save all education entries */
    draftEducations.forEach(async (educationDraft, index) => {
      if (educationDraft.createdAt === '' || educationDraft.createdAt === null) {
        /* No createdAt === create */
        try {
          const newEducation = await createEducation(educationDraft);
          if (newEducation) {
            setDraftEducations(prevState => {
              const newState = [...prevState];
              newState[index] = newEducation;
              return newState;
            });
            setOriginalEducations(prevState => [...prevState, newEducation]);
          }
        } catch (error) {
          encounteredError++;
        }
      } else if (originalEducations.find(e => e.id === educationDraft.id)) {
        /* Id exists in educations === patch */
        try {
          const changedEducation = await patchEducation(educationDraft);
          if (changedEducation) {
            setDraftEducations(prevState => {
              const newState = [...prevState];
              newState[index] = changedEducation;
              return newState;
            });
            setOriginalEducations(prevState => {
              const newState = [...prevState];
              const originalIndex = prevState.findIndex(e => e.id === changedEducation.id);
              newState[originalIndex] = changedEducation;
              return newState;
            });
          }
        } catch (error) {
          encounteredError++;
        }
      }
    });

    originalEducations.forEach(async educationDraft => {
      if (!draftEducations.find(e => e.id === educationDraft.id)) {
        /* Id no longer in draftEducations === delete */
        try {
          await deleteEducation(educationDraft.id);
          setOriginalEducations(prevState => prevState.filter(e => e.id !== educationDraft.id));
        } catch (error) {
          encounteredError++;
        }
      }
    });

    if (encounteredError > 0) {
      setFormIsSubmitting(false);
      (toastState as ToastState<VioletToast>).add(
        {
          description: 'Something went wrong. Please check for errors.',
          type: 'error'
        },
        { timeout: 8000 }
      );
      return;
    }

    window.scrollTo(0, 0);
    (toastState as ToastState<VioletToast>).add(
      {
        description: 'Your data has been saved.',
        type: 'success'
      },
      { timeout: 8000 }
    );

    setFormIsSubmitting(false);
    setHasAttemptedSubmit(false);
  };

  const memoizedEducationContextValue = useMemo(
    () => ({
      draftEducations,
      invalidRowIndices,
      originalEducations,
      setDraftEducations,
      setInvalidRowIndices,
      setOriginalEducations
    }),
    [originalEducations, draftEducations, invalidRowIndices]
  );

  return (
    <EducationsContext.Provider value={memoizedEducationContextValue}>
      <form
        ref={formRef}
        noValidate
        onSubmit={handleFormSubmit}
      >
        <UserEducationForm hasAttemptedSubmit={hasAttemptedSubmit} />
        <ButtonGroup
          align="right"
          size="large"
        >
          <Button
            data-cy="save-button"
            isLoading={formIsSubmitting}
            size="large"
            type="submit"
          >
            Save
          </Button>
        </ButtonGroup>
      </form>
    </EducationsContext.Provider>
  );
};

export default EducationPage;
