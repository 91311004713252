import styled from 'styled-components';

import {
  DESKTOP_BREAKPOINT_RANGE,
  MOBILE_BREAKPOINT_RANGE,
  TEXT_STYLES
} from '../../../../constants';

export const Buttons = styled.div`
  margin-top: 3rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    display: grid;
    row-gap: 1.125rem;
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    display: grid;
    justify-content: end;
  }
`;

export const FormHeading = styled.h2`
  color: var(--gray-800);
  ${TEXT_STYLES.headlineSectionLevel2Regular}
`;

export const FormRow = styled.div`
  display: grid;
  margin-bottom: 1rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    row-gap: var(--space-8);
  }

  ${DESKTOP_BREAKPOINT_RANGE} {
    column-gap: var(--space-4);
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
`;

export const FormWrapper = styled.div`
  border-radius: 1rem;
  border: 1px solid var(--gray-200);
  background: #fff;
  padding: 1rem;
`;

export const Label = styled.div`
  ${TEXT_STYLES.formsLabelRegular}
  color: var(--gray-800);
  opacity: 0.8;
  margin-bottom: 0.75rem;
`;
