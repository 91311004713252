import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Education } from 'src/pages/Dashboard/utils';

interface ContextValue {
  draftEducations: Education[];
  invalidRowIndices: number[];
  originalEducations: Education[];
  setDraftEducations: Dispatch<SetStateAction<Education[]>>;
  setInvalidRowIndices: Dispatch<SetStateAction<number[]>>;
  setOriginalEducations: Dispatch<SetStateAction<Education[]>>;
}

// @ts-expect-error
export const EducationsContext = createContext<ContextValue>(null);

const useEducationsContext = () => useContext(EducationsContext);

export const isInvalidNumber = (value: number) => isNaN(value) || value <= 0;

export const revalidateEducationRows = (draftEducations: Education[]) => {
  const invalidIndices = draftEducations.reduce<number[]>((invalidArray, education, index) => {
    if (
      isInvalidNumber(Number(education.credits)) ||
      Number(education.credits) < 0 ||
      education.organization === '' ||
      education.courseName === '' ||
      isInvalidNumber(Number(education.year)) ||
      Number(education.year) < new Date().getFullYear() - 70 ||
      Number(education.year) > new Date().getFullYear()
    ) {
      invalidArray.push(index);
    }
    return invalidArray;
  }, []);
  return invalidIndices;
};

export default useEducationsContext;
