import { Temporal } from '@js-temporal/polyfill';
import { Cell, Column, Row, TableBody, TableHeader } from 'react-stately';
import EmptyTableState from 'src/components/Table/EmptyState';
import TableCenterCell from 'src/components/Table/TableCenterCell';
import TableLinkedCell from 'src/components/Table/TableLinkedCell';
import TableNumberCell from 'src/components/Table/TableNumberCell';
import { CourseCollectionCompletion } from 'src/pages/Dashboard/utils';

import BooleanCheck from '../BooleanCheck';
import CollectionActionButton from '../CollectionActionButton';

import * as S from './styles';

interface Props {
  collectionCompletions: CourseCollectionCompletion[];
}

const DesktopTable = ({ collectionCompletions }: Props) => (
  <S.EducationTable
    aria-label="Completed collections table"
    renderEmptyState={() => (
      <EmptyTableState
        colSpan={6}
        message="No collections have been completed."
      />
    )}
  >
    <TableHeader>
      <Column key="name">
        <S.TableHeaderCell>NAME</S.TableHeaderCell>
      </Column>
      <Column>
        <S.TableHeaderCell $align="end">YEAR</S.TableHeaderCell>
      </Column>
      <Column>
        <S.TableHeaderCell $align="end">CREDITS/HOURS</S.TableHeaderCell>
      </Column>
      <Column>
        <S.TableHeaderCell $align="center">CLAIMED</S.TableHeaderCell>
      </Column>
      <Column>
        <S.TableHeaderCell $align="center">GENERATED CERTIFICATE</S.TableHeaderCell>
      </Column>
      <Column>
        <S.TableHeaderCell>ACTION</S.TableHeaderCell>
      </Column>
    </TableHeader>
    <TableBody>
      {collectionCompletions.map(completion => {
        const { collection } = completion;
        return (
          <Row
            key={completion.id}
            data-cy="completed-collection"
          >
            <Cell data-cy="collection-name">
              <TableLinkedCell to={`/dashboard/education/collections/${collection.id}`}>
                {collection.name}
              </TableLinkedCell>
            </Cell>
            <Cell data-cy="collection-year">
              <TableNumberCell>
                {completion.completedAt !== null
                  ? Temporal.PlainDate.from(completion.completedAt).year
                  : ''}
              </TableNumberCell>
            </Cell>
            <Cell data-cy="collection-credits">
              <TableNumberCell>{collection.credits ?? 0}</TableNumberCell>
            </Cell>
            <Cell data-cy="collection-claimed">
              <TableCenterCell>
                <BooleanCheck
                  dataCy="collection-claimed"
                  value={collection.evaluation !== null}
                />
              </TableCenterCell>
            </Cell>
            <Cell data-cy="collection-certificate">
              <TableCenterCell>
                <BooleanCheck
                  dataCy="collection-certificate"
                  value={collection.certificates.length > 0}
                />
              </TableCenterCell>
            </Cell>
            <Cell data-cy="collection-action">
              <CollectionActionButton collection={collection} />
            </Cell>
          </Row>
        );
      })}
    </TableBody>
  </S.EducationTable>
);

export default DesktopTable;
