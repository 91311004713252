import { Link } from 'react-router-dom';
import { FOCUS_STYLES, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const BlankText = styled.div`
  text-align: center;
`;

export const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  border: 1px solid var(--gray-200);
  background-color: #fff;
  border-radius: 1rem;
  padding: 1rem;
`;

export const CardRow = styled.div`
  display: grid;
  grid-template-columns: 0.75fr 1.15fr;
  gap: 1rem;
`;

export const Label = styled.div`
  ${TEXT_STYLES.formsLabelMediumSmallCaps}
  color: var(--purple-400);
`;

export const MobileCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Value = styled.div``;

export const ValueLink = styled(Link)`
  color: var(--blue-500);
  text-decoration: none;

  &:active {
    color: var(--blue-600);
    ${FOCUS_STYLES}
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    ${FOCUS_STYLES}
  }
`;
