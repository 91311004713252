import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import ScreenSizeWarning from 'src/components/ScreenSizeWarning';
import SecondaryNavigation from 'src/components/SecondaryNavigation';
import ToastProvider from 'src/components/ToastProvider';
import useUser from 'src/hooks/useUser';

import useBreakpointRange from '../../../../../hooks/useBreakpointRange';

import * as S from './styles';

const Wrapper = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { isInMobileBreakpointRange } = useBreakpointRange();

  useEffect(() => {
    if (!user.member_roles.includes('superuser')) {
      /* user does not have permission to access organization data */
      if (user.member_roles.includes('network_manager')) {
        navigate('/dashboard/my-network', { replace: true });
      } else {
        navigate('/dashboard', { replace: true });
      }
    }
  }, [user.member_roles, navigate]);

  if (isInMobileBreakpointRange) {
    return <ScreenSizeWarning />;
  }

  const myOrganizationLinks = [
    { exact: true, link: '/dashboard/my-organization', title: 'Overview' },
    {
      link: '/dashboard/my-organization/Inclusivity',
      title: 'Inclusivity'
    },
    { link: '/dashboard/my-organization/demographics', title: 'Demographics' },
    {
      link: encodeURI(
        `/dashboard/my-organization/education-engagement?order_by[column]=user_name&order_by[dir]=asc&resource_type=course_collection`
      ),
      title: 'Education engagement'
    },
    {
      link: encodeURI(
        '/dashboard/my-organization/assignments?order_by[column]=end_date&order_by[dir]=desc&page=1'
      ),
      title: 'Assignments'
    }
  ];

  return (
    <ToastProvider>
      {toastState => (
        <>
          <SecondaryNavigation
            linkList={myOrganizationLinks}
            navTitle="My organization"
          />
          <S.Main>
            <Outlet context={toastState} />
          </S.Main>
        </>
      )}
    </ToastProvider>
  );
};

export default Wrapper;
