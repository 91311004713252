import * as S from './styles';

interface Props {
  dataCy: string;
  value: boolean;
}

const BooleanCheck = ({ dataCy, value }: Props) =>
  value ? (
    <S.CheckedIcon
      aria-label="checked"
      data-cy={`${dataCy}-checked`}
      role="img"
    />
  ) : (
    <S.UnCheckedIcon
      aria-label="unchecked"
      data-cy={`${dataCy}-unchecked`}
      role="img"
    />
  );

export default BooleanCheck;
