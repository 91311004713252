import { ReactComponent as CheckIcon } from '@material-symbols/svg-400/rounded/check_circle-fill.svg';
import Button from 'src/components/Buttons/Button';
import Spinner from 'src/components/Spinner';
import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const CheckedIcon = styled(CheckIcon)`
  width: 1.25rem;
  height: 1.25rem;
  fill: var(--success-400) !important;
`;

export const EmptyState = styled.div`
  ${TEXT_STYLES.formsLabelRegular}
  border: 1px solid var(--gray-200);
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  text-align: center;
`;

export const ExportButton = styled(Button)``;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const TableActionsWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  gap: 0.5rem;
`;

export const TableDescription = styled.div`
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-800);
`;

export const TableHeaderWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

export const TableTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-grow: 1;
`;

export const TableTitle = styled.h2`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--gray-800);
  margin: 0;
`;

export const TableWrapper = styled.div`
  border-radius: 1rem;
  border: 1px solid var(--gray-200);
  background: #fff;
  padding: 1.5rem;
  margin-bottom: 1rem;
`;

export const ToastSpinner = styled(Spinner)`
  height: 1.5rem;
  width: 1.5rem;
`;
