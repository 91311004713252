import { ReactNode } from 'react';

import * as S from './styles';

interface Props {
  actions?: ReactNode;
  children: ReactNode;
  description?: string;
  title: string;
  titleLevel?: 'h1' | 'h2';
}

const TableWrapperWithHeader = ({
  actions,
  children,
  description,
  title,
  titleLevel = 'h2'
}: Props) => (
  <S.TableWrapper>
    <S.TableHeaderWrapper>
      <S.TableTextWrapper>
        {titleLevel === 'h1' ? (
          <S.TableTitleH1>{title}</S.TableTitleH1>
        ) : (
          <S.TableTitleH2>{title}</S.TableTitleH2>
        )}
        <S.TableDescription>{description}</S.TableDescription>
      </S.TableTextWrapper>

      {actions !== undefined && <S.TableActionsWrapper>{actions}</S.TableActionsWrapper>}
    </S.TableHeaderWrapper>
    {children}
  </S.TableWrapper>
);

export default TableWrapperWithHeader;
