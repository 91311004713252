import { ReactComponent as ChevronLeft } from '@material-design-icons/svg/round/arrow_back_ios.svg';
import { ReactComponent as ChevronRight } from '@material-design-icons/svg/round/arrow_forward_ios.svg';
import * as Sentry from '@sentry/react';
import { produce } from 'immer';
import { DOMAttributes, useEffect, useRef, useState } from 'react';
import { AriaRadioGroupProps, AriaTextFieldProps } from 'react-aria';
import { useNavigate, useParams } from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';
import { useLocalStorage } from 'usehooks-ts';

import Button from '../../../../../../components/Buttons/Button';
import RadioGroup from '../../../../../../components/FormFields/RadioGroup';
import Radio from '../../../../../../components/FormFields/RadioGroup/Radio';
import Spinner from '../../../../../../components/Spinner';
import useOpenErrorModalDialog from '../../../../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../../../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../../../../../hooks/useUser';

import ExitConfirmationModalDialog from './ExitConfirmationModalDialog';
import * as S from './styles';

const fixedQuestions = [
  'The content included in the activity.',
  'The presentation of the content.',
  'Knowledge check questions.',
  'The overall delivery of the session.',
  'I gained new knowledge as a result of completing this activity.',
  'I learned new skills as a result of completing this activity.',
  'I am confident in my ability to use what I learned from this activity in my practice.',
  'The information presented motivates me to change aspects of my current practice or treatment habits.',
  'The information presented motivates me to enhance my team-based practices.',
  'I see a connection between this information and how I care for my patients.',
  'Please list any practice changes you plan on making as a result of what you learned from this session. If you do not plan on making any changes, please write "No changes."',
  'Please share your feedback on the program, including whether it met your expectations and if any accommodation requests were adequately addressed.'
];

const AccreditationSurvey = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const { collectionId } = useParams<{ collectionId: string }>();
  const [answers, setAnswers] = useLocalStorage<Record<string, string>>(
    `${user.id}-accreditationSurveyAnswers-${collectionId}`,
    {}
  );

  const openErrorModalDialog = useOpenErrorModalDialog();

  const openSignedOutModalDialog = useOpenSignedOutModalDialog();

  const {
    close: closeExitConfirmationModalDialog,
    isOpen: exitConfirmationModalDialogIsOpen,
    open: openExitConfirmationModalDialog
  } = useOverlayTriggerState({});

  const { bearerToken } = useUser();

  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [questionsAndPossibleAnswers, setQuestionsAndPossibleAnswers] =
    useState<{ possibleAnswers: string[]; questions: string[] }[]>();
  const [step, setStep] = useState(0);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    (async () => {
      const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/course_collections/${collectionId}`;

      const options: RequestInit = {
        headers: {
          Authorization: `Bearer ${bearerToken}`
        }
      };

      try {
        const response = await fetch(url, options);

        if (!response.ok) {
          if (response.status === 401) {
            openSignedOutModalDialog();
            return;
          } else if (response.status === 404) {
            navigate('/dashboard/education/collections');
            return;
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        }

        const { data } = (await response.json()) as APICourseCollectionsCollectionId;

        if (data.completed_course_count < data.course_count) {
          // user has not finished this collection
          navigate(`/dashboard/education/collections/${data.id}`, { replace: true });
        }
        if (data.evaluation) {
          // user already finished evaluating this collection
          navigate(`/dashboard/education/collections/${data.id}/accreditation-certificate`, {
            replace: true
          });
        }

        setQuestionsAndPossibleAnswers([
          {
            possibleAnswers: ['Not at all met', 'Partially met', 'Completely met'],
            questions: data.learning_objectives
          },
          {
            possibleAnswers: ['Low quality', 'Average quality', 'High quality', 'N/A'],
            questions: [fixedQuestions[0], fixedQuestions[1], fixedQuestions[2], fixedQuestions[3]]
          },
          {
            possibleAnswers: ['Strongly disagree', 'Disagree', 'Agree', 'Strongly agree', 'N/A'],
            questions: [
              fixedQuestions[4],
              fixedQuestions[5],
              fixedQuestions[6],
              fixedQuestions[7],
              fixedQuestions[8],
              fixedQuestions[9]
            ]
          }
        ]);
      } catch (error) {
        Sentry.captureException(error);
        openErrorModalDialog();
      }

      setIsLoading(false);
    })();
  }, [bearerToken, collectionId, navigate, openErrorModalDialog, openSignedOutModalDialog]);

  useEffect(() => {
    if (!user.permissions.includes('evaluations.create')) {
      // user does not have permission to evaluate accredited collections
      navigate(`/dashboard/education/collections/${collectionId}`, { replace: true });
    }
  }, [user.permissions, navigate, collectionId]);

  const handleBackButtonPress = () => {
    setStep(step - 1);
    window.scrollTo(0, 0);
  };

  const handleCloseButtonPress = () => {
    openExitConfirmationModalDialog();
  };

  const handleFeedbackTextFieldChange: AriaTextFieldProps['onChange'] = value => {
    setAnswers(
      produce(answers, draft => {
        draft[fixedQuestions[11]] = value;
      })
    );
  };

  const handleFormSubmit: DOMAttributes<HTMLFormElement>['onSubmit'] = async event => {
    event.preventDefault();

    if (formRef.current?.checkValidity() === false) {
      formRef.current.reportValidity();
      return;
    }

    if (step < 3) {
      setStep(step + 1);
      window.scrollTo(0, 0);
    } else {
      setFormIsSubmitting(true);

      try {
        const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/course_resource_evaluations`;

        const getRating = (question: string, possibleAnswers: string[]) =>
          answers[question] === 'N/A' ? -1 : possibleAnswers.indexOf(answers[question]);

        const getAgreementRating = (question: string) =>
          getRating(question, ['Strongly disagree', 'Disagree', 'Agree', 'Strongly agree']);

        const getSatisfactionRating = (question: string) =>
          getRating(question, ['Low quality', 'Average quality', 'High quality']);

        const options: RequestInit = {
          body: JSON.stringify({
            course_resource_evaluation: {
              answers: {
                agreement: [
                  {
                    question: 'new_knowledge',
                    rating: getAgreementRating(fixedQuestions[4])
                  },
                  {
                    question: 'new_skills',
                    rating: getAgreementRating(fixedQuestions[5])
                  },
                  {
                    question: 'confidence',
                    rating: getAgreementRating(fixedQuestions[6])
                  },
                  {
                    question: 'motivate_treatment',
                    rating: getAgreementRating(fixedQuestions[7])
                  },
                  {
                    question: 'motivate_team',
                    rating: getAgreementRating(fixedQuestions[8])
                  },
                  {
                    question: 'care',
                    rating: getAgreementRating(fixedQuestions[9])
                  }
                ],
                free_text: {
                  feedback: answers[fixedQuestions[11]],
                  practice_changes: answers[fixedQuestions[10]]
                },
                learning_objectives: Object.entries(answers)
                  .filter(([_key, value]) =>
                    ['Not at all met', 'Partially met', 'Completely met'].includes(value)
                  )
                  .map(([key, value]) => ({
                    question: key,
                    rating: ['Not at all met', 'Partially met', 'Completely met'].indexOf(value)
                  })),
                satisfaction: [
                  {
                    question: 'content',
                    rating: getSatisfactionRating(fixedQuestions[0])
                  },
                  {
                    question: 'presentation',
                    rating: getSatisfactionRating(fixedQuestions[1])
                  },
                  {
                    question: 'knowledge',
                    rating: getSatisfactionRating(fixedQuestions[2])
                  },
                  {
                    question: 'delivery',
                    rating: getSatisfactionRating(fixedQuestions[3])
                  }
                ]
              },
              course_resource_id: collectionId
            }
          }),
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            'Content-Type': 'application/json'
          },
          method: 'POST'
        };

        const response = await fetch(url, options);

        if (!response.ok) {
          if (response.status === 401) {
            openSignedOutModalDialog();
            return;
          } else {
            throw new Error(`${response.status} (${response.statusText})`);
          }
        }

        navigate(`/dashboard/education/collections/${collectionId}/accreditation-certificate`);
        window.scrollTo(0, 0);
      } catch (error) {
        Sentry.captureException(error);
        openErrorModalDialog();
      }

      setFormIsSubmitting(false);
    }
  };

  const handlePracticeChangesTextFieldChange: AriaTextFieldProps['onChange'] = value => {
    setAnswers(
      produce(answers, draft => {
        draft[fixedQuestions[10]] = value;
      })
    );
  };

  const handleRadioGroupChange: (question: string) => AriaRadioGroupProps['onChange'] =
    question => value => {
      setAnswers(
        produce(answers, draft => {
          draft[question] = value;
        })
      );
    };

  const backButton = (
    <S.BackButton
      data-cy="back-button"
      leadingIcon={ChevronLeft}
      onPress={handleBackButtonPress}
      variant="empty"
    >
      Back
    </S.BackButton>
  );

  const getListOfQuestions = (step: number) =>
    questionsAndPossibleAnswers![step].questions.map(question => (
      <S.Container
        key={question}
        data-cy="evaluation-question"
      >
        <S.Statement id={question}>{question}</S.Statement>
        <RadioGroup
          aria-labelledby={question}
          isRequired
          onChange={handleRadioGroupChange(question)}
          size="small"
          validationBehavior="native"
          value={answers[question]}
        >
          {questionsAndPossibleAnswers![step].possibleAnswers.map(possibleAnswer => (
            <Radio
              key={possibleAnswer}
              data-cy="evaluation-answer"
              value={possibleAnswer}
            >
              {possibleAnswer}
            </Radio>
          ))}
        </RadioGroup>
      </S.Container>
    ));

  const nextButton = (
    <S.NextButton
      data-cy="next-button"
      trailingIcon={ChevronRight}
      type="submit"
      variant="empty"
    >
      Next
    </S.NextButton>
  );

  if (isLoading) {
    return (
      <S.SpinnerWrapper>
        <Spinner />
      </S.SpinnerWrapper>
    );
  }

  return (
    <>
      <S.Main>
        <S.CloseButton.Root
          data-cy="close-button"
          onPress={handleCloseButtonPress}
        >
          <S.CloseButton.Icon />
        </S.CloseButton.Root>
        <S.Content>
          <S.ProgressBar
            aria-label="Evaluation progress"
            maxValue={4}
            minValue={0}
            value={step}
          />
          <form
            ref={formRef}
            noValidate
            onSubmit={handleFormSubmit}
          >
            {step === 0 && (
              <>
                <S.Title>To what extent were the learning objectives of this activity met?</S.Title>
                {getListOfQuestions(step)}
                <S.NavigationButtons>
                  <div />
                  {nextButton}
                </S.NavigationButtons>
              </>
            )}
            {step === 1 && (
              <>
                <S.Title>Please rate the quality of the following from this collection.</S.Title>
                {getListOfQuestions(step)}
                <S.NavigationButtons>
                  {backButton}
                  {nextButton}
                </S.NavigationButtons>
              </>
            )}
            {step === 2 && (
              <>
                <S.Title>
                  Please indicate the extent to which you agree with the following statements.
                </S.Title>
                {getListOfQuestions(step)}
                <S.NavigationButtons>
                  {backButton}
                  {nextButton}
                </S.NavigationButtons>
              </>
            )}
            {step === 3 && (
              <>
                <S.PracticeChangesTextFieldLabel id="B3zeAzYJOI">
                  {fixedQuestions[10]}
                </S.PracticeChangesTextFieldLabel>
                <S.PracticeChangesTextField
                  aria-labelledby="B3zeAzYJOI"
                  data-cy="practice-changes-field"
                  isMultiline
                  isRequired
                  onChange={handlePracticeChangesTextFieldChange}
                  validationBehavior="native"
                  value={answers[fixedQuestions[10]]}
                />
                <S.FeedbackTextFieldLabel id="S3NdD2A6Fc">
                  {fixedQuestions[11]}
                </S.FeedbackTextFieldLabel>
                <S.FeedbackTextField
                  aria-labelledby="S3NdD2A6Fc"
                  data-cy="feedback-field"
                  isMultiline
                  isRequired
                  onChange={handleFeedbackTextFieldChange}
                  validationBehavior="native"
                  value={answers[fixedQuestions[11]]}
                />
                <S.NavigationButtons>
                  {backButton}
                  <Button
                    data-cy="submit-button"
                    isLoading={formIsSubmitting}
                    size="large"
                    type="submit"
                  >
                    Submit
                  </Button>
                </S.NavigationButtons>
              </>
            )}
          </form>
        </S.Content>
      </S.Main>
      {exitConfirmationModalDialogIsOpen && (
        <ExitConfirmationModalDialog close={closeExitConfirmationModalDialog} />
      )}
    </>
  );
};

export default AccreditationSurvey;
