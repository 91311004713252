import { ReactComponent as KeyboardDown } from '@material-symbols/svg-400/rounded/keyboard_arrow_down-fill.svg';
import { ReactComponent as KeyboardUp } from '@material-symbols/svg-400/rounded/keyboard_arrow_up-fill.svg';
import { RefObject, useEffect, useState } from 'react';
import { Location } from 'react-router-dom';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import { LinkList } from '..';
import { isExactPathMatch, isPartialPathMatch } from '../utils';

import * as S from './styles';

interface Props {
  linkList: LinkList[];
  linksRef?: RefObject<HTMLDivElement>;
  location: Location;
  navTitle: string;
  selectedParentLink: LinkList | null;
}

const MobileSecondaryNavigation = ({
  linkList,
  linksRef,
  location,
  navTitle,
  selectedParentLink
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [childTitle, setChildTitle] = useState<string | null>(null);

  useEffect(() => {
    if (selectedParentLink?.parentLink !== undefined) {
      const child = selectedParentLink.children?.find(child => {
        const isExact = child.exact !== undefined ? child.exact : false;
        return isExact
          ? isExactPathMatch(location.pathname, selectedParentLink.parentLink ?? '', child.link)
          : isPartialPathMatch(location.pathname, selectedParentLink.parentLink ?? '', child.link);
      });
      setChildTitle(child?.title ?? null);
    }
  }, [selectedParentLink, location.pathname]);

  return (
    <div
      ref={linksRef}
      data-cy="secondary-nav-links"
    >
      <S.SecondaryNavToggleMenu
        aria-controls="mobile-secondary-nav"
        aria-expanded={isOpen}
        onPress={() => setIsOpen(!isOpen)}
      >
        <S.SecondaryNavToggleMenuTitle>
          {navTitle}
          {selectedParentLink?.title !== undefined ? ` | ${selectedParentLink.title}` : ''}
          {isNonEmptyString(childTitle) ? ` | ${childTitle}` : ''}
        </S.SecondaryNavToggleMenuTitle>
        {isOpen ? <KeyboardUp /> : <KeyboardDown />}
      </S.SecondaryNavToggleMenu>
      {isOpen && (
        <S.SecondaryNavigationBar
          aria-label="Mobile Secondary Navigation"
          id="mobile-secondary-nav"
          role="menu"
        >
          {linkList.map(link => {
            if (link.hideCondition !== undefined && link.hideCondition) return null;
            return (
              <S.ParentLinkWrapper
                key={link.link}
                className={selectedParentLink?.link === link.link ? 'is-selected' : undefined}
              >
                <S.ParentLink
                  data-label={link.title}
                  onClick={link.onClick}
                  role="menuitem"
                  to={link.link}
                >
                  {link.title}
                </S.ParentLink>
                {link.children && link.children.length > 0 && (
                  <S.ChildNavigation
                    className={
                      selectedParentLink?.link === link.link ? 'active-child-nav' : undefined
                    }
                  >
                    <S.ChildNavigationWrapper data-cy="children-nav">
                      {link.children.map(child => {
                        const isExact = child.exact !== undefined ? child.exact : false;
                        if (child.hideCondition !== undefined && child.hideCondition) return null;
                        return (
                          <S.ChildLinkWrapper
                            key={child.link}
                            className={
                              (isExact &&
                                location.pathname ===
                                  `${selectedParentLink?.parentLink}${child.link.split('?')[0]}`) ||
                              (!isExact &&
                                location.pathname.includes(
                                  `${selectedParentLink?.parentLink}${child.link.split('?')[0]}`
                                ))
                                ? 'is-selected'
                                : undefined
                            }
                          >
                            <S.ChildLink
                              data-label={child.title}
                              onClick={child.onClick}
                              role="menuitem"
                              to={`${link.parentLink}${child.link}`}
                            >
                              {child.title}
                            </S.ChildLink>
                          </S.ChildLinkWrapper>
                        );
                      })}
                    </S.ChildNavigationWrapper>
                  </S.ChildNavigation>
                )}
              </S.ParentLinkWrapper>
            );
          })}
        </S.SecondaryNavigationBar>
      )}
    </div>
  );
};

export default MobileSecondaryNavigation;
